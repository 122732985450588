import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function CustomAutocomplete(props) {
    const[options,setOptions]=React.useState([])
    const [value, setValue] = React.useState(props.options[0]);
    const [inputValue, setInputValue] = React.useState('');
    
    React.useEffect(()=>{
        if(props.options){
            console.log(props.options)
            setOptions(props.options)
            setValue(null)
        }
    },[props.options])
    return (
        <Autocomplete
            value={value??null}
            size="small"
            onChange={(event, newValue) => {
                setValue(newValue);
                props.onUpdate(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setOptions(String(newInputValue).length>0?props.options.filter((item)=>String(item.name).toLowerCase().includes(newInputValue.toLowerCase())):props.options)
            }}
            id={String(props.label).concat("-controllable-states-demo")??"controllable-states-demo"}
            options={options}
            getOptionLabel={(option) => props?.optionLabel?option[`${props.optionLabel}`]:option.name}
            renderInput={(params) => <TextField {...params} variant="standard" label={props.label} />}
            sx={{fontSize:14}}
        />
    );
}
