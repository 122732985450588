import { configureStore } from "@reduxjs/toolkit";
import MyCardSlice from "./slices/MyCardSlice";
import OrgSlice from "./slices/OrgSlice";
import AuthSlice from "./slices/AuthSlice";
import TransactionSlice from "./slices/TransactionSlice";
import GeoDataSlice from "./slices/GeoDataSlice";
import ServiceSlice from "./slices/ServiceSlice";
import MyServicesSlice from "./slices/MyServicesSlice";
import RewardSlice from "./slices/RewardSlice";
import OperatorSlice from "./slices/OperatorSlice";
import SystemConfigSlice from "./slices/ConfigSlice";
import FamilyCardSlice from "./slices/FamilyCardSlice";
import POSDeviceSlice from "./slices/POSDeviceSlice";


export default configureStore({
    reducer: {
        myCard: MyCardSlice,
        myFamilyCards: FamilyCardSlice,
        orgList: OrgSlice,
        myDetails: AuthSlice,
        operatorDetails: OperatorSlice,
        myTransactions: TransactionSlice,
        geoData: GeoDataSlice,
        services: ServiceSlice,
        myServices: MyServicesSlice,
        myReward: RewardSlice,
        systemConfig : SystemConfigSlice,
        orgDevices: POSDeviceSlice
    },
});
