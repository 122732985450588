import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

BkashLogo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function BkashLogo({ disabledLink = false, sx }) {
    const {i18n} =useTranslation()
    const theme = useTheme();
    const navigate = useNavigate()

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR
    const logo = <Box onClick={()=>navigate({pathname:'/dashboard/my-card'})} component="img" src={String(process.env.PUBLIC_URL).concat(i18n.language==='bn'?"/static/images/bkash_bn.png":"/static/images/bkash_en.png")} sx={{ ...sx }} />

    if (disabledLink) {
        return <>{logo}</>;
    }

    return logo;
}
