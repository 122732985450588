import * as React from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { FormControl, InputLabel, MenuItem, Select,FormHelperText,Slide,Box,Typography } from '@mui/material';
import { fetchUserDetail } from '../../../store/slices/AuthSlice';
import { fetchGeoDataThunk } from '../../../store/slices/GeoDataSlice';
import { API } from '../../../Config';
import Iconify from '../../../components/Iconify';
import store from '../../../store/Store';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function AddressUpdate(props) {
    const { open, onClose, onUpdate } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userDetail = useSelector(state => state.myDetails.data)
    const dispath = useDispatch()
    const navigate = useNavigate()

    const [postalCodes,setPostCodes] = React.useState([])
    const [cities,setcities] = React.useState([])
    const [upazilas, setUpazilas] = React.useState([])
    const states = useSelector(state => state.geoData.data.divisions)

    const applyCard=async (setSubmitting)=>{
        await API.post('org/card/apply/', props.applyCardData).then(res => {
            setSubmitting(false)
            console.log(res.data)
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
            navigate('/dashboard/my-card', { replace: true });
        }).catch(err => {
            setSubmitting(false)
            console.log(err.response?.data?.error_detail)
            if (err.response.data.errors) {
                console.log('applyCardErrors',err.response.data.errors)
            }
        })
    }

    const updateAddress = async (values, { setSubmitting, setErrors, resetForm }) => {
        API.post("auth/user/address/", values).then(async res => {
            console.log('update res', res.data)
            resetForm()
            // onClose(false)
            dispath(fetchUserDetail())
            await applyCard(setSubmitting)
        }).catch(err => {
            console.log(err.response)
            setSubmitting(false)
            enqueueSnackbar(err.response.data.message, {
                variant: "warning",
            });
        })
    }
    const updateForm = useFormik({
        initialValues: {
            address_line_1: userDetail.address_line_1?.state?userDetail.address_line_1.state:'',
            address_line_2: userDetail.user_address?.address_line_2?userDetail.user_address.address_line_2:'',
            city:userDetail.user_address?.city?userDetail.user_address.city:'',
            upazila: userDetail.user_address?.upazila ? userDetail.user_address.upazila : '',
            state: userDetail.user_address?.state?userDetail.user_address.state:'',
            post_code:userDetail.user_address?.postal_code?userDetail.user_address.postal_code:'',

        },
        validationSchema: Yup.object().shape({
            address_line_1: Yup.string().required('This field is must'),
            address_line_2: Yup.string(),
            city: Yup.string().required("City is must"),
            upazila: Yup.string().required("Upazila is must"),
            state: Yup.string().required("State is must"),
            post_code: Yup.string(),
        }),
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: updateAddress
    })
    React.useEffect(() => {
        dispath(fetchGeoDataThunk())
    }, [])
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            navigate('/dashboard/my-card')
            // return
        onClose(false);
    };
    const onStateChange = (event) => {
        updateForm.setValues({ ...updateForm.values, 'state': event.target.value,'city':'','upazila':'','post_code':'' })
        setcities(Array.from(store.getState().geoData.data.districts).filter(e=>e.division_id===String(states.find(e=>e.name===event.target.value).id)))
    }
    const onCityChange = (event) => {
        updateForm.setValues({ ...updateForm.values, 'city': event.target.value, 'post_code': '','upazila': '' })
        setUpazilas(Array.from(store.getState().geoData.data.upazilas).filter(e => e.district_id === String(cities.find(e => e.name === event.target.value).id)))
        // setPostCodes(Array.from(store.getState().geoData.data.postcodes).filter(e=>e.district_id===String(cities.find(e=>e.name===event.target.value).id)))
    }
    const onUpazilaChange = (event) => {
        updateForm.setValues({ ...updateForm.values, 'upazila': event.target.value, 'post_code': '' })
        // setPostCodes(Array.from(store.getState().geoData.data.postcodes).filter(e => e.district_id === String(cities.find(e => e.name === event.target.value).id)))
    }
    const onPostCodeChange = (event) => {
        updateForm.setValues({ ...updateForm.values, 'post_code': event.target.value})
    }

    return (
        <div>
            <Box>
                <Typography>Provide Mail Address</Typography>
                <Stack>
                    <Typography variant='h6'>
                        To receive NFC Card at home please help us with valid information.
                    </Typography>
                    <Stack spacing={2} display="flex" direction={"column"}>
                        <FormControl fullWidth size='small'>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="address_line_1"
                                name="address_line_1"
                                label="House / Street"
                                required
                                type="text"
                                fullWidth
                                variant="standard"
                                value={updateForm.values.address_line_1}
                                onChange={updateForm.handleChange}
                                error={!!updateForm.errors.address_line_1}
                                helperText={updateForm.errors?.address_line_1}
                                minRows={2}
                                multiline
                            />
                        </FormControl>
                        {/* <FormControl fullWidth size='small'>
                            <TextField
                                margin="dense"
                                id="address_line_2"
                                name="address_line_2"
                                label="Address Line 2"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={updateForm.values.address_line_2}
                                onChange={updateForm.handleChange}
                                error={!!updateForm.errors.address_line_2}
                                helperText={updateForm.errors?.address_line_2}
                                minRows={1}
                                multiline
                            />
                        </FormControl> */}
                        <FormControl fullWidth size='small' error={!!updateForm.errors.state}>
                            <InputLabel id="state">Division</InputLabel>
                            <Select
                                labelId="state"
                                id="state"
                                name="state"
                                variant="standard"
                                value={updateForm.values.state}
                                onChange={onStateChange}
                                label="Division"
                            >
                                {states?.length > 0 && states.map((item, idx) => (
                                    <MenuItem value={item.name} key={idx}>
                                        <em>{item.name}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{updateForm.errors?.state}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth size='small' error={!!updateForm.errors.city}>
                            <InputLabel id="city">City</InputLabel>
                            <Select
                                labelId="city"
                                id="city"
                                name="city"
                                variant="standard"
                                value={updateForm.values.city}
                                onChange={onCityChange}
                                label="City"
                            >
                                {cities.length > 0 && cities.map((item, idx) => (
                                    <MenuItem value={item.name} key={idx}>
                                        <em>{item.name}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{updateForm.errors?.city}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth size='small' error={!!updateForm.errors.upazila}>
                            <InputLabel id="upazila">Upazila</InputLabel>
                            <Select
                                labelId="upazila"
                                id="upazila"
                                name="upazila"
                                variant="standard"
                                value={updateForm.values.upazila}
                                onChange={onUpazilaChange}
                                label="Upazila"
                            >
                                {upazilas.length > 0 && upazilas.map((item, idx) => (
                                    <MenuItem value={item.name} key={idx}>
                                        <em>{item.name}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{updateForm.errors?.upazila}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth size='small' error={!!updateForm.errors.post_code}>
                            {/* <InputLabel id="post_code">Post Code</InputLabel>
                            <Select
                                labelId="post_code"
                                id="post_code"
                                name="post_code"
                                variant="standard"
                                value={updateForm.values.post_code}
                                onChange={onPostCodeChange}
                                label="Post Code"
                            >
                                {postalCodes.length > 0 && postalCodes.map((item, idx) => (
                                    <MenuItem value={item.postCode} key={idx}>
                                        <em>{String(item.postCode).concat(' - ').concat(item.postOffice)}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{updateForm.errors?.post_code}</FormHelperText> */}
                            <TextField
                                autoFocus
                                margin="dense"
                                id="post_code"
                                name="post_code"
                                label="Post Code"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={updateForm.values.post_code}
                                onChange={updateForm.handleChange}
                                error={!!updateForm.errors.post_code}
                                helperText={updateForm.errors?.post_code}
                            />
                        </FormControl>
                    </Stack>

                </Stack>
                <Box sx={{display:"flex",justifyContent:"center",alignItems:"flex-start"}}>
                    {/* <IconWrapperStyle onClick={updateForm.handleSubmit}
                        sx={{
                            mt: 2,
                            color: (theme) => theme.palette.primary.dark,
                            cursor: 'pointer',
                            backgroundImage: (theme) =>
                                `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
                                    theme.palette.primary.dark,
                                    0.24
                                )} 100%)`,
                        }}
                    >
                        <Iconify icon={"line-md:arrow-right-circle"} sx={{ height: 100, width: 100 }} />
                    </IconWrapperStyle>
                    <Button color='error' onClick={handleClose}>Cancel</Button> */}
                    <LoadingButton disabled={!updateForm.isValid} type="button" loading={updateForm.isSubmitting} onClick={updateForm.handleSubmit}><Iconify icon={"line-md:arrow-right-circle"} sx={{ height: 100, width: 100 }} /></LoadingButton>
                </Box>
            </Box>
        </div>
    );
}