import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../Config'

const initialState = {
  data:[],
  status:'idle',
  error:''
}

export const fetchRewardHistory = createAsyncThunk('reward/history/list/', async () => {
  const response = await API.get('nfccards/card/redeem/list/')
  console.log('rewards',response)
  return response.data
})

export const rewardSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    resetState: (state) => {
      console.log('dispatching ----- ')
      state.data = {}
    },
  },
  extraReducers: {
    [fetchRewardHistory.pending]: (state) => {
      state.status = 'loading'
    },
    [fetchRewardHistory.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.data = action.payload
    },
    [fetchRewardHistory.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

// Action creators are generated for each case reducer function
export const { resetState } = rewardSlice.actions
export default rewardSlice.reducer
