import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Button } from '@mui/material';
import { API, USER_GROUPS } from '../../Config';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import { capitalizeFirstLetter } from '../../helper';
import { resetState } from '../../store/slices/AuthSlice';
import ChangePasswordDialog from './inc/ChangePasswordDialog';



// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Home',
        icon: 'eva:home-fill',
        linkTo: '/',
    },
    {
        label: 'Profile',
        icon: 'eva:person-fill',
        linkTo: '/dashboard/profile',
    },
    {
        label: 'Change Password',
        icon: 'eva:settings-2-fill',
        linkTo: '#',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover(props) {
    const anchorRef = useRef(null);
    const history = useNavigate()
    const user = useSelector(state => state.myDetails.data)
    const [open, setOpen] = useState(null);
    const dispatch = useDispatch();
    const [showChangePassDialog, setShowChangePassDialog] = useState(false)

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };
    const handleLogout = () => {
        API.get('auth/logout/').then((res) => {
            setOpen(null);
            localStorage.clear()
            dispatch(resetState())
            history('/login')
        }).catch(err => {
            setOpen(null);
            localStorage.clear()
            history('/login')
        })
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar src={props.data.profile_pic ? props.data.profile_pic : String(process.env.PUBLIC_URL).concat("/static/images/unisex-avatar.webp")} alt="photoURL" sx={{ border: '2px solid' }} />
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {`${capitalizeFirstLetter(user?.first_name)} ${user?.last_name}`}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    <MenuItem to={'/dashboard/my-card'} component={RouterLink}>
                        My Card
                    </MenuItem>
                    <MenuItem to={'/dashboard/profile'} component={RouterLink}>
                        Profile
                    </MenuItem>
                    <MenuItem to={'#'} component={RouterLink} onClick={() => setShowChangePassDialog(true)}>
                        Change Password
                    </MenuItem>

                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </MenuPopover>
            <ChangePasswordDialog open={showChangePassDialog}
                title="Change Passowrd"
                // onEnable={(val)=>{dispatch(fetchMyCard());setShowActivationDialog(false);}}
                onClose={(val) => { setShowChangePassDialog(val) }} />
        </>
    );
}
