import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../Config'

const initialState = {
    data: {},
    status: 'idle',
    error: ''
}

export const fetchUserDetail = createAsyncThunk('auth/user/details/', async () => {
    const response = await API.get('auth/user/details/')
    console.log('myDetails', response)
    return response.data
})

export const authSlice = createSlice({
    name: 'myDetail',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = {}
        },
    },
    extraReducers: {
        [fetchUserDetail.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchUserDetail.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.data = action.payload.data
        },
        [fetchUserDetail.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetState } = authSlice.actions
export default authSlice.reducer
