import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/system';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import PPSLogo from '../components/PPSLogo';
// sections
import { RegisterForm } from '../sections/auth/register';
import AuthSocial from '../sections/auth/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    // bottom:'215px',
    maxWidth: 464,
    display: 'flex',
    // minHeight: '100vh',
    // maxHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(10, 0),
    // [theme.breakpoints.down('md')]: {
    //     padding: theme.spacing(20, 0),
    // },
}));

// ----------------------------------------------------------------------

export default function Register() {
    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');

    return (
        <Page title="Register">
            <RootStyle>
                <HeaderStyle>
                    {smUp && (
                        <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                            Already registered? {''}
                            <Link variant="subtitle2" component={RouterLink} to="/login">
                                Login
                            </Link>
                        </Typography>
                    )}
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        {/* <Typography variant="h3" sx={{ px: 5, mt: '-285px', mb: 5 }}>
                            Manage the cashless payment more effectively with CMS
                        </Typography>
                        <img alt="register" src={String(process.env.PUBLIC_URL).concat("/static/illustrations/illustration_register.png")} /> */}
                        <img height={100} loading='lazy' src={String(process.env.PUBLIC_URL).concat("/static/images/bkash_en.png")} alt="login" />
                        {/* <Typography mt={1} sx={{marginLeft:'-30px'}} variant='poster' fontFamily={'Comic Sans MS, Comic Sans, cursive'}>Card Management System</Typography> */}
                    </SectionStyle>
                )}

                <Container>
                    <ContentStyle>
                        <Typography variant="h4" gutterBottom sx={(theme)=>({[theme.breakpoints.up('md')]:{mb:3}})}>
                            Registration - Get Started
                        </Typography>

                        {/* <Typography sx={{ color: 'text.secondary', mb: 1 }}>Create an account</Typography> */}

                        {/* <AuthSocial /> */}

                        <RegisterForm />

                        {/* <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                            By registering, I agree to CMS&nbsp;
                            <Link underline="always" color="text.primary" href="#">
                                Terms of Service
                            </Link>
                            {''} and {''}
                            <Link underline="always" color="text.primary" href="#">
                                Privacy Policy
                            </Link>
                            .
                        </Typography> */}

                        {!smUp &&  <Typography variant="body2" sx={{ mt:2 , textAlign: 'center' }}>
                            Already have an account?{' '}
                            <Link variant="subtitle2" to="/login" component={RouterLink}>
                                Login
                            </Link>
                        </Typography> }
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
