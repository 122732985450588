import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Stack, Typography, TextField, Box, FormControl, InputAdornment } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import { useFormik } from "formik";
import { useSnackbar } from 'notistack';
import { PUBLIC_FORM_API, PUBLIC_API, API, TOKEN, USER_GROUPS } from "../../Config";
import Page from '../../components/Page';
import useResponsive from "../../hooks/useResponsive";
import AddressUpdate from "./inc/AddressUpdate";
import { fetchGeoDataThunk } from "../../store/slices/GeoDataSlice";


export default function VerifyNAddress({ color = "primary" }) {
    const smUp = useResponsive('up', 'sm');
    const dispatch = useDispatch()
    const location = useLocation()
    const [sendingOTP, setSendingOTP] = React.useState(false)
    const [otpVerified, setOTPVerified] = React.useState(false)
    const [showTimer, setShowTimer] = React.useState(true)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [regData, setRegData] = useState({})
    const [applyCardData, setApplyCardData] = useState({})
    const onEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            verifyForm.handleSubmit()
        }
    }
    const sendOTP = () => {
        let phone = ""
        let email = ""
        const regData = JSON.parse(sessionStorage.getItem("reg_data"))
        Object.entries(regData).forEach((item, idx) => {
            if (item[1] !== null && item[1] !== undefined) {
                if (item[0] === "phone") {
                    phone = item[1]
                }
                else if (item[0] === "email") {
                    email = item[1]
                }
            }
        })
        setSendingOTP(true)
        PUBLIC_API.post('auth/user/otp/send/', { phone, email }).then(res => {
            console.log(res.data)
            setShowTimer(true)
            setSendingOTP(false)
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
        }).catch(err => {
            console.log(err.response.data.message)
            enqueueSnackbar(err.response.data.message, {
                variant: "warning",
            });
            setSendingOTP(false)
        })
    }
    const OTPButton = () => (
        <LoadingButton type='button' variant='outlined' onClick={sendOTP} loading={sendingOTP} disabled={showTimer}>{showTimer === false ? 'Resend' : 'Try after a minute'}</LoadingButton>
    )
    const login = async (loginData, setSubmitting) => {
        await PUBLIC_API.post("auth/login/", loginData).then(async loginRes => {
            console.log("loginRes", loginRes.data.success === true)
            if (loginRes.data?.success === true) {
                localStorage.setItem(TOKEN, loginRes.data.token)
                localStorage.setItem(USER_GROUPS, JSON.stringify(loginRes.data.data.groups))
                // setOTPVerified(true)
                await applyCard()
                setSubmitting(false)
                navigate('/dashboard/my-card', { replace: true });
            }
        }).catch(err => {
            console.log(err.response?.data)
            setSubmitting(false)
        })
    }
    const applyCard = async () => {
        await API.post('org/card/apply/', applyCardData).then(res => {
            console.log(res.data)
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
        }).catch(err => {
            console.log(err.response?.data?.error_detail)
            if (err.response.data.errors) {
                console.log('applyCardErrors', err.response.data.errors)
            }
        })
    }
    const register = async (values, { setSubmitting, setErrors, resetForm }) => {
        const formData = new FormData()
        // const regData = JSON.parse(sessionStorage.getItem("reg_data"))
        Object.entries(regData).forEach((item, idx) => {
            if (item[1] !== null && item[1] !== undefined) {
                formData.append(item[0], item[1])
            }
        })
        formData.append('otp', values.otp)
        if (location.state?.photo) {
            formData.append("usr_profile_pic", location.state?.photo);
        }
        await PUBLIC_FORM_API.post('auth/registration/', formData).then(async res => {
            console.log(res.data)
            resetForm()
            await login({ email: String(regData.email).length>0?regData.email:regData.phone, password: regData.password }, setSubmitting)
            
        }).catch(err => {
            setSubmitting(false)
            console.log(err.response?.data?.error_detail)
            if (err.response?.data?.errors) {
                setErrors(err.response.data.errors)
            }
            enqueueSnackbar(err.response.data?.errors?.otp, {
                variant: "warning",
            });
        })
    };
    const verifyForm = useFormik({
        initialValues: {
            otp: ""
        },
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: register
    })
    React.useEffect(() => {
        if (location.state?.applyCardData && location.state?.regData) {
            setApplyCardData({ ...location.state.applyCardData, delivery_method: location.state.regData.delivery_method })
            setRegData(location.state.regData)
        }
        else {
            // return navigate("/register-apply",{paramStr:paramStr})
        }
        dispatch(fetchGeoDataThunk())
        const timeout = setTimeout(() => {
            setShowTimer(false)
        }, 60000);

        return () => clearTimeout(timeout)
    }, []);
    React.useEffect(() => {
        let timeout = null

        if (showTimer) {
            timeout = setTimeout(() => {
                setShowTimer(false)
            }, 60000);
        }
        return () => clearTimeout(timeout)
    }, [showTimer]);
    return (
        <>
            <Page title="Verify OTP">
                <Container>
                    <Grid container spacing={2} sx={{ height: "100vh" }}>
                        <Grid item xs={12} sm={12} md={6} mdOffset={3} display="flex" direction="row" justifyContent="center" alignItems={"center"}>
                            {!otpVerified && (<Box>
                                <Stack spacing={2}>
                                    <Typography variant="h6">Please submit otp to verify your phone number</Typography>
                                    <FormControl >
                                        <TextField
                                            fullWidth
                                            required
                                            id="otp"
                                            name="otp"
                                            type="number"
                                            value={verifyForm.values.otp}
                                            onChange={verifyForm.handleChange}
                                            error={!!verifyForm.errors.otp}
                                            helperText={'Enter 6 digit OTP'}
                                            placeholder="type your OTP"
                                            label="OTP"
                                            onKeyDown={onEnterKeyPress}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <OTPButton />
                                                    </InputAdornment>
                                            }}
                                        />
                                    </FormControl>
                                    <LoadingButton fullWidth size="medium" type="button" onClick={verifyForm.handleSubmit} variant="contained" loading={verifyForm.isSubmitting}>
                                        Submit
                                    </LoadingButton>
                                </Stack>
                            </Box>)}
                            {otpVerified && <AddressUpdate applyCardData={applyCardData} />}
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    )
}