// scroll bar
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux'
import store from './store/Store'
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import './i18n';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
        <HashRouter>
            <Provider store={store}>
                <SnackbarProvider maxSnack={3}>
                    <React.Suspense>
                        <App />
                    </React.Suspense>
                </SnackbarProvider>
            </Provider>
        </HashRouter>
    </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
