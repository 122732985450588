import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material
import {
    Stack, IconButton, Tooltip, Zoom,
    Container, Card,
    Typography, Box, CardContent, List
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSnackbar } from 'notistack';
import useResponsive from "../../hooks/useResponsive";
import Page from '../../components/Page';
import { fetchRewardHistory } from '../../store/slices/RewardSlice';
import Transaction from '../../components/dma-card-components/Transaction';

export default function MyReward({ color = 'primary', ...other }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const myCard = useSelector(state => state.myCard.data)
    const myReward = useSelector(state => state.myReward.data)

    useEffect(() => {
        dispatch(fetchRewardHistory())
    }, [])
    return (
        <>
            <Page title="NFC Card detail">
                <Container>
                    <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" mb={smUp ? 5 : 2}>
                        <Typography variant="h4" gutterBottom>
                            My NFC Card reward
                        </Typography>
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} mdOffset={3}>
                            <Stack spacing={1.5}>
                                <Card
                                    sx={{
                                        // py: !hasCard ? 5 : 0,
                                        boxShadow: 5,
                                        borderRadius: 2,
                                        color: (theme) => theme.palette[color].darker,
                                        background: 'none',
                                        bgcolor: (theme) => theme.palette[color].lighter,
                                    }}
                                >
                                    <CardContent>
                                        <Box display="flex" flexDirection="column" justifyContent="flex-start">
                                            {/* <Typography variant="h5" gutterBottom>
                                            Card details
                                        </Typography> */}
                                            <Typography sx={{ fontSize: 14, fontWeight: "bold", alignSelf: "center" }} color="text.secondary">
                                                Available Points
                                            </Typography>
                                            <Typography sx={{ fontSize: 64, fontWeight: "bold", alignSelf: "center" }} color="text.secondary">
                                                {myReward?.reward_points ?? 0}
                                            </Typography>
                                            <Typography sx={{ fontSize: 14, fontWeight: "bold", alignSelf: "center" }} color="text.secondary">
                                                {myReward?.last_redeem_date ?? ""}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>

                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} mdOffset={3}>
                            <Card
                                sx={{
                                    // py: !hasCard ? 5 : 0,
                                    boxShadow: 5,
                                    borderRadius: 2,
                                    color: (theme) => theme.palette[color].darker,
                                    background: 'none',
                                    bgcolor: (theme) => theme.palette[color].lighter,
                                }}
                            >
                                <CardContent>
                                    <Box display="flex" flexDirection="column" justifyContent="flex-start" sx={{
                                        height: myReward?.redeems?.length>0?500:100,
                                        overflow: "hidden",
                                        overflowY: "scroll",
                                        '&::-webkit-scrollbar': {
                                            width: 0,
                                        }
                                    }}>
                                        {(myCard?.request?.status === "ACTIVATED" && myReward?.redeems) ? (
                                            <List>{myReward.redeems.map((item, idx) => (<Transaction key={idx} data={item} />))}</List>
                                        ) : (
                                            <Stack display={"flex"} direction={"column"} justifyContent={"center"} alignItems={"center"} height={200}>
                                                <Typography alignSelf={"center"} sx={{ m: "auto" }} variant="subtitle1">No record yet</Typography>
                                            </Stack>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    )
}