import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
    Stack,
    Button,
    Container,
    Typography,
    Box, Input, InputAdornment,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import LoaderDialog from '../../components/LoaderDialog';
import { API } from '../../Config';
import { fetchOperatorDetail } from '../../store/slices/OperatorSlice';

// mock
// import customers from '../_mock/user';


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Customer() {
    const dispatch = useDispatch()
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState()
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [customers, setCustomers] = useState([])
    // op codes
    const operatorDetails = useSelector(state=>state.operatorDetails.data);
    const [showLoaderDialog, setShowLoaderDialog] = useState(false)

    useEffect(()=>{
        console.log('op',operatorDetails)
        if(operatorDetails){
            API.get(`org/customer/list/${operatorDetails.org_id}/`).then(res=>{
                console.log('customers',res.data.data)
                setCustomers(Array.from(res.data.data).map((item, idx) => {
                    return {
                        id: idx + 1,
                        card_number: item.card.card_number,
                        lastName: item.user.last_name,
                        firstName: item.user.first_name,
                        email: item.user.email,
                        phone: item.user.phone
                    }
                }))
            }).catch(err=>{
                console.log('err',err)
            })
        }
        else{
            dispatch(fetchOperatorDetail())
        }
    },[operatorDetails])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = customers.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByCardNumber = (event) => {
        setFilterName(event.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            setCustomers(customers.filter((item, idx) => String(item.card_number).includes(searchText)))
        }
    }

    const filteredUsers = applySortFilter(customers, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    // React.useEffect(() => {
    //     API.get('plm/customer/list/').then(res => {
    //         console.log(res.data.data)
    //         if (res.statusText === "OK") {
    //             setCustomers(Array.from(res.data.data).map((item, idx) => {
    //                 return {
    //                     id: idx + 1,
    //                     card_number: item.card.card_number,
    //                     lastName: item.user.last_name,
    //                     firstName: item.user.first_name,
    //                     email: item.user.email,
    //                     phone: item.user.phone
    //                 }
    //             }))
    //         }
    //     })
    // }, [])
    const columns = [
        { field: 'id', headerName: '#', width: 10 },
        {
            field: 'card_number', headerName: 'Card Number', width: 230,
            renderCell: (params) => {
                return <Typography variant='span' sx={{ fontWeight: 'bold' }}>{params.row.card_number}</Typography>
            }
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 230,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        { field: 'email', headerName: 'Email', width: 200 },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 130,
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const api = params.api;
                    const thisRow = {};

                    api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach(
                        (c) => { thisRow[c.field] = params.getValue(params.id, c.field) }
                    );

                    return alert(JSON.stringify(thisRow, null, 4));
                };

                return <Button disabled variant='contained' onClick={onClick}>View</Button>;
            }
        },
    ];

    return (
        <>
            <Page title="Customers">
                <LoaderDialog open={showLoaderDialog} url={null} />
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Customers
                        </Typography>
                        <Button variant="contained" component={RouterLink} to="/dashboard/customers/add" startIcon={<Iconify icon="eva:plus-fill" />}>
                            New Customer
                        </Button>
                    </Stack>

                    <Box>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Input
                                autoFocus
                                fullWidth
                                disableUnderline
                                placeholder="Search…"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyPress={handleKeyPress}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                    </InputAdornment>
                                }
                                sx={{ mr: 1, fontWeight: 'fontWeightBold', borderBottom: 1, borderRadius: 1, borderColor: 'grey.500' }}
                            />
                            <Button variant="contained" onClick={() => { }}>
                                Search
                            </Button>
                        </Stack>
                        <DataGrid
                            autoHeight {...customers}
                            disableSelectionOnClick
                            rows={customers}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            density="comfortable"
                            // checkboxSelection
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            onRowClick={(rowData) => { console.log(rowData.row) }}
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                                    outline: "none !important",
                                },
                            }}
                        />
                    </Box>
                </Container>
            </Page>
        </>
    );
}
