import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../Config'

const initialState = {
    data: null,
    status: 'idle',
    error: ''
}

export const fetchOperatorDetail = createAsyncThunk('org/operator/details/', async () => {
    const response = await API.get('org/operator/details/')
    console.log('operatorDetail', response)
    return response.data
})

export const operatorSlice = createSlice({
    name: 'operatorDetail',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = {}
        },
    },
    extraReducers: {
        [fetchOperatorDetail.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchOperatorDetail.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.data = action.payload.data
        },
        [fetchOperatorDetail.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetState } = operatorSlice.actions
export default operatorSlice.reducer
