
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';

// ----------------------------------------------------------------------

export default function App(props) {
    

    return (
        <ThemeProvider>
            <ScrollToTop />
            {/* <BaseOptionChartStyle /> */}
            <Router />
        </ThemeProvider>
    );
}
