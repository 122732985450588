import * as React from 'react';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Slide,Box,Stack,Typography,TextField,FormControl, IconButton,InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { API } from '../../../Config';
import Iconify from '../../../components/Iconify';



const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChangePasswordDialog(props) {
    const { open, onClose, title } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return
        changePasswordForm.handleReset()
        onClose(false);
    };
    const ChangePassSchema = Yup.object().shape({
        old_password: Yup.string().required('Old Password is required').min(12).max(100),
        new_password: Yup.string().required('New Password is required').min(12).max(100).matches(
            /^(?=.*[a-z])/,
            " Must Contain One Lowercase Character"
        ).matches(
            /^(?=.*[A-Z])/,
            "  Must Contain One Uppercase Character"
          )
          .matches(
            /^(?=.*[0-9])/,
            "  Must Contain One Number Character"
          )
          .matches(
            /^(?=.*[!@#%^&*])/,
            "  Must Contain  One Special Case Character"
          ),
        confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    });
    const changePassword=(values, { setSubmitting, setErrors, resetForm })=>{
        setSubmitting(true)
        API.post("auth/user/password/change/",values).then(res=>{
            console.log(res.data)
            setSubmitting(false)
            if(res.statusText==="OK"){
                enqueueSnackbar(res.data.message, {
                    variant: "success",
                });
                changePasswordForm.handleReset();
                onClose(false);
            }
        }).catch(err=>{
            console.log(err.response)
            setSubmitting(false)
            setErrors(err.response?.data?.errors)
        })
    }
    const changePasswordForm = useFormik({
        initialValues: {
            old_password:'',
            new_password:'',
            confirm_password:''
        },
        validationSchema: ChangePassSchema,
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: changePassword
    })
    const onEnterKeyPress=(event)=>{
        if(event.key==="Enter"){
            changePasswordForm.handleSubmit()
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} fullWidth={'sm'} maxWidth={'sm'}>
                <DialogTitle>
                    <Stack flexDirection={"row"} justifyContent={'space-between'}>
                        <Typography alignSelf={"center"} variant='h6'>{title}</Typography>
                        <IconButton type='button' onClick={handleClose} size='medium'>
                            <Iconify icon={"line-md:close"}/>
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Stack spacing={3}>
                            {/* <Typography variant="body1">Please fillup the form</Typography> */}
                            <FormControl>
                                <TextField
                                    fullWidth
                                    size='small'
                                    variant='standard'
                                    required
                                    id="old_password"
                                    name="old_password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={changePasswordForm.values.old_password}
                                    onChange={changePasswordForm.handleChange}
                                    onKeyDown={onEnterKeyPress}
                                    error={!!changePasswordForm.errors.old_password}
                                    helperText={changePasswordForm.errors.old_password??'Enter current password'}
                                    placeholder="type your current password"
                                    label="Current Password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    size='small'
                                    variant='standard'
                                    required
                                    id="new_password"
                                    name="new_password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={changePasswordForm.values.new_password}
                                    onChange={changePasswordForm.handleChange}
                                    onKeyDown={onEnterKeyPress}
                                    error={!!changePasswordForm.errors.new_password}
                                    helperText={changePasswordForm.errors.new_password??'Enter 12 digit new password'}
                                    placeholder="type your new password"
                                    label="New Password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    size='small'
                                    variant='standard'
                                    required
                                    id="confirm_password"
                                    name="confirm_password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={changePasswordForm.values.confirm_password}
                                    onChange={changePasswordForm.handleChange}
                                    onKeyDown={onEnterKeyPress}
                                    error={!!changePasswordForm.errors.confirm_password}
                                    helperText={changePasswordForm.errors.confirm_password??'Confirm new password'}
                                    placeholder="confirm your new password"
                                    label="Confirm New Password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                            <LoadingButton fullWidth size="small" type="button" onClick={changePasswordForm.handleSubmit} variant="contained" loading={changePasswordForm.isSubmitting}>
                                Submit
                            </LoadingButton>
                        </Stack>
                    </Box>
                </DialogContent>

                {/* <DialogActions>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <LoadingButton type="button" loading={isSubmitting} onClick={handleConfirm}>Confirm</LoadingButton>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}