import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { API } from '../../Config'

const initialState = {
    cards: [],
    card_requests:[],
    status: 'idle',
    error: ''
}

export const fetchFamilyCardsThunk = createAsyncThunk('nfccards/child/cards/', async () => {
    const response = await API.get('nfccards/child/cards/')
    console.log('family cards', response)
    return response.data
})

export const familyCardSlice = createSlice({
    name: 'familyCardSlice',
    initialState,
    reducers: {
        resetState: (state) => {
            console.log('dispatching ----- ')
            state.data = []
        },
        removeCard: (state, itemToRemoveID) => {
            console.log('dispatching ----- ')
            state.data = state.data.filter(item=> item.id!==itemToRemoveID);
        },
        setFamilyCardSliceData: (state, action)=>{
            const {cards, card_requests:cardRequests} = action.payload;
            state.card_requests=cardRequests;
            state.cards=cards;
        }
    },
    extraReducers: {
        [fetchFamilyCardsThunk.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchFamilyCardsThunk.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.card_requests = action.payload.card_requests;
            state.cards = action.payload.cards;
        },
        [fetchFamilyCardsThunk.rejected]: (state, action) => {
            console.log('error',action);
            state.status = 'failed';
            state.error = action.error.message;
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetState, removeCard, setFamilyCardSliceData } = familyCardSlice.actions
export default familyCardSlice.reducer
