import moment from "moment";
import { API, PERMISSIONS, USER_GROUPS } from "./Config"
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { saveAs } from "file-saver";


export const userGroups = () => {
    const groups = localStorage.getItem(USER_GROUPS)!==null?JSON.parse(localStorage.getItem(USER_GROUPS)):[]
    return groups
}

export const hasGroup = (group) => {
    const groups = userGroups()
    if (groups.includes(group)) {
        return true
    }
    return false
}
export const hasPermission=(permission)=>{
    if(localStorage.getItem(PERMISSIONS)!==null){
        const permissions = localStorage.getItem(PERMISSIONS).split(',')
        return permissions.includes(permission)
    }
    return false
}
export function arrayRemoveItem(arr, value) {
    return arr.filter(ele=> {
        // console.log('ele',ele,'value',value)
        return ele !== value;
    });
}

export const getBuildDate = (epoch) => {
    const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
    return buildDate;
};
export function capitalize(string) {
    if (string !== undefined) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return ''
}

export function capitalizeFirstLetter(string="") {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uniqueElements(array){
    return array.filter((v, i, a) => a.indexOf(v) === i);
}


