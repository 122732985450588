import * as React from 'react';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Slide,Box,Stack,Typography,TextField,FormControl, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { API } from '../../../Config';
import Iconify from '../../../components/Iconify';



const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CardEnableDialog(props) {
    const { open, onClose, onEnable, title, cardNumber } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [instruction,setInstruction] =React.useState('');
    const [requestingCode,setReqCode]=React.useState(false);
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return
        enableCardForm.handleReset()
        onClose(false);
    };
    const ValidationSchema = Yup.object().shape({
        otp: Yup.string().required('OTP is required'),
    });
    const enableCard=(values, { setSubmitting, setErrors, resetForm })=>{
        setSubmitting(true)
        API.post("nfccards/card/enable/",values).then(res=>{
            console.log(res.data)
            setSubmitting(false)
            if(res.statusText==="OK"){
                enqueueSnackbar(res.data.message, {
                    variant: "success",
                });
                enableCardForm.handleReset()
                onEnable(true)
            }
        }).catch(err=>{
            console.log(err.response)
            setSubmitting(false)
            setErrors(err.response?.data?.errors)
        })
    }
    const enableCardForm = useFormik({
        initialValues: {
            otp:'',
        },
        validationSchema: ValidationSchema,
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: enableCard
    })
    React.useEffect(() => {
        if(open){
            setReqCode(true);
            API.get('nfccards/activate/scan/'.concat(String(cardNumber).slice(String(cardNumber).length-3,String(cardNumber).length)).concat("/")).then(res=>{
                enqueueSnackbar(res.data.message, {
                    variant: "success",
                });
                setInstruction(res.data.message);
            }).catch(err=>{
                console.log(err.response?.message)
                enqueueSnackbar(err.response?.message, {
                    variant: "error",
                });
            }).finally(()=>{
                setReqCode(false);
            })
        }
    }, [open])
    const onEnterKeyPress=(event)=>{
        if(event.key==="Enter"){
            enableCardForm.handleSubmit()
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>
                    <Stack flexDirection={"row"} justifyContent={'space-between'}>
                        <Typography alignSelf={"center"} variant='h6'>{title}</Typography>
                        <IconButton type='button' onClick={handleClose} size='medium'>
                            <Iconify icon={"line-md:close"}/>
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Stack>
                            <Typography variant="body2">{instruction}</Typography>
                            <Typography variant="subtitle1">Please submit to enable your NFC Card</Typography>
                            <FormControl sx={{marginTop:3, marginBottom:3}}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    variant='standard'
                                    required
                                    id="otp"
                                    name="otp"
                                    type="number"
                                    value={enableCardForm.values.otp}
                                    onChange={enableCardForm.handleChange}
                                    onKeyDown={onEnterKeyPress}
                                    error={!!enableCardForm.errors.otp}
                                    helperText={enableCardForm.errors.otp??'Enter 6 digit code'}
                                    placeholder="type your code"
                                    label="Verification Code"
                                />
                            </FormControl>
                            <LoadingButton fullWidth size="small" type="button" onClick={enableCardForm.handleSubmit} variant="contained" loading={enableCardForm.isSubmitting || requestingCode}>
                                Submit
                            </LoadingButton>
                        </Stack>
                    </Box>
                </DialogContent>

                {/* <DialogActions>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <LoadingButton type="button" loading={isSubmitting} onClick={handleConfirm}>Confirm</LoadingButton>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}