import axios from 'axios';
import { API_BASE_URLS, MEDIA_BASE_URLS } from './common/base_urls';

const status = process.env.REACT_APP_ENV
export const API_URL = API_BASE_URLS[status]
export const TOKEN = "access-token"
export const REFRESH_TOKEN = "refresh-token"
export const USER_ID = "user_id"
export const USER_GROUPS = "user_groups"
export const USER_DATA = "user_data"
export const PERMISSIONS = "user_permissions"
export const BASE_URL = MEDIA_BASE_URLS[status]
export const credentials = {
    "GOOGLE_CLIENT_ID": "705019730299-kfb5arnuisah2135tka05uka6h7v901a.apps.googleusercontent.com",
    "FACEBOOK_APP_ID": "788095958681659"
}
export const API = axios.create({
    baseURL: API_URL,
    timeout: 100000,
    headers: {
        "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`,
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*"
    }
})
export const FILE_API = axios.create({
    baseURL: API_URL,
    timeout: 100000,
    headers: {
        "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`,
        "Content-Type": "multipart/form-data",
        // "Access-Control-Allow-Origin": "*"
    }
})
API.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// axios interceptor for refreshing access/id token
API.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalConfig = error.config;
        if (error.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true
            try {
                const updateTokenRes = await PUBLIC_API.post('api/token/refresh/', {
                    refresh: localStorage.getItem(REFRESH_TOKEN),
                });
                console.log('refresh',updateTokenRes.data)
                const {access} = updateTokenRes.data;
                localStorage.setItem(TOKEN, access);
                error.response.config.headers.Authorization = `Bearer ${access}`;
                return API(error.response.config);
            } catch (_error) {
                localStorage.clear();
                window.location.replace('/')
                return Promise.reject(_error);
            }
        }
        return Promise.reject(error);
    }
);

FILE_API.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export const PUBLIC_API = axios.create({
    baseURL: API_URL,
    timeout: 100000,
    // headers:{
    //   "Content-Type": "application/json",
    //   'Access-Control-Allow-Origin': true,
    // }
})

export const PUBLIC_FORM_API = axios.create({
    baseURL: API_URL,
    timeout: 100000,
    headers: {
        "Content-Type": "multipart/form-data",
    }
})

export const isLoggedIn = () => {
    if (localStorage.getItem(TOKEN) === null) {
        return false;
    }
    return true;
}

// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function JsonClient(endpoint, { body, ...customConfig } = {}) {
    const headers = { 'Content-Type': 'application/json' }
    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`,
            'Content-Type': 'application/json',
            ...customConfig.headers,
        },
    }

    if (body) {
        config.body = JSON.stringify(body)
    }

    let data
    // const navigate = useNavigate()
    try {
        const response = await window.fetch(API_URL + endpoint, config)
        data = await response.json()
        console.log('status', response.statusText)
        if (response.ok) {
            return data
        }
        if (response.statusText === "Unauthorized") {
            // navigate('/login')
            // window.location.replace('/#/login');
            throw new Error(response.statusText)
        }
        console.log('endpoint', endpoint, 'error_code', response.ok)
        // localStorage.clear()
    } catch (err) {
        localStorage.clear()
        // navigate('/login')
        window.location.replace('/#/login');
        return Promise.reject(err.message ? err.message : data)
    }
}

JsonClient.get = function (endpoint, customConfig = {}) {
    return JsonClient(endpoint, { ...customConfig, method: 'GET' })
}

JsonClient.post = function (endpoint, body, customConfig = {}) {
    return JsonClient(endpoint, { ...customConfig, body })
}


export const drawerWidth = 312
export const PALETTE_MODE = "light"


export async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`,
            // 'Content-Type': 'application/json'
            'Content-Type': 'multipart/form-data',
        },
        body: data // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}