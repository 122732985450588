import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../Config'

const initialState = {
  data:{},
  has_card:false,
  status:'idle',
  error:''
}

export const fetchMyServices = createAsyncThunk('nfccards/card/binding/list/', async () => {
  const response = await API.get('nfccards/card/binding/list/')
  console.log('myServices',response)
  return response.data
})

export const myServices = createSlice({
  name: 'myServices',
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = {}
    },
  },
  extraReducers: {
    [fetchMyServices.pending]: (state) => {
      state.status = 'loading'
    },
    [fetchMyServices.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.data = action.payload.data
      state.has_card=action.payload.has_card
    },
    [fetchMyServices.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

// Action creators are generated for each case reducer function
export const { resetState } = myServices.actions
export default myServices.reducer
