import * as Yup from 'yup';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material
import {
    Stack,
    Container, Card,
    Typography, FormControl, Radio,FormControlLabel,RadioGroup,FormLabel, FormHelperText
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { API } from '../../Config';
// components
import Page from '../../components/Page';
import WaitDialog from './inc/WaitDialog';
import UpdateAddressDialog from './inc/UpdateAddressDialog';
import { fetchServiceListThunk } from '../../store/slices/ServiceSlice';
import CustomAutocomplete from '../../components/CustomAutocomplete';

// ----------------------------------------------------------------------
const IndicatorSeparator = ({ innerProps }) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
};
const indicatorSeparatorStyle = {
    alignSelf: 'stretch',
    backgroundColor: "#29265f",
    marginBottom: 8,
    marginTop: 8,
    width: 2,
};

export default function ApplyCard() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [showWaitDialog, setShowWaitDialog] = useState(false)
    const [showAddressUpdateDialog, setShowAddressUpdateDialog] = useState(false)
    const dispatch = useDispatch()
    const serviceList = useSelector(state => state.services.data)
    const [selectedService, setSelectedService] = useState(null)
    const myDetails = useSelector(state => state.myDetails.data)
    const [orgServiceList, setOrgServiceList] = useState([])
    const [orgMerchantList, setOrgMerchantList] = useState([])

    const [url, setUrl] = useState(null)

    const ApplyCardSchema = Yup.object().shape({
        service_id: Yup.number().required('Provider is required'),
        service_type: Yup.string().required('Service is required'),
        service_merchant: Yup.number().required('Payment Method is required'),
        delivery_method:Yup.string().required("We need to know how you would like to receive your card")
    });
    const defaultValues = {
        service_id: '',
        service_type: '',
        service_merchant: '',
        delivery_method:''
    };
    const applyCard = (values, { setSubmitting, setErrors, resetForm }) => {
        API.post('org/card/apply/', values).then(res => {
            setSubmitting(false)
            console.log(res.data)
            resetForm()
            setOrgServiceList([])
            setOrgMerchantList([])
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
            if (res.data.data?.url) {
                setShowWaitDialog(true);
                setUrl(res.data.data.url);
            }
            else {
                navigate('/dashboard/my-card', { replace: true });
            }
        }).catch(err => {
            setSubmitting(false)
            console.log(err.response?.data?.error_detail)
            if (err.response.data.errors) {
                setErrors(err.response.data.errors)
            }
        })
        // navigate('/dashboard', { replace: true });
    };
    const applyForm = useFormik(
        {
            initialValues: defaultValues,
            validationSchema: ApplyCardSchema,
            validateOnBlur: false,
            validateOnChange: true,
            onSubmit: applyCard
        }
    )

    React.useEffect(() => {
        dispatch(fetchServiceListThunk())
    }, [])
    React.useEffect(() => {
        console.log(applyForm.values)
    }, [applyForm.values])
    React.useEffect(() => {
        if (myDetails?.user_address === null) {
            setShowAddressUpdateDialog(true)
        }
        else {
            setShowAddressUpdateDialog(false)
        }
    }, [myDetails])
    
    const handleServiceChoose = (service) => {
        if(service!==null){
            setSelectedService(service)
            applyForm.setValues({ ...applyForm.values, 'service_type': service.name })
            API.get('org/service/list/'.concat(service.name).concat('/')).then(res => {
                console.log(res.data)
                setOrgServiceList(res.data.data)
                setOrgMerchantList([])
            })
        }
    }
    const handleProviderChange = (value) => {
        if(value!==null){
            applyForm.setValues({ ...applyForm.values, 'service_id': value.id, 'service_type': value.type })
            API.get(`org/service/payment-service/list/${value.type}/${value.id}/`).then((res) => {
                console.log('org merchants', res.data.data)
                setOrgMerchantList(res.data.data)
            })
        }
    }
    const handleMerchantChange=(value)=>{
        if(value!=null){
            applyForm.setValues({ ...applyForm.values, 'service_merchant': value.service_merchant_id })
        }
    }
    return (
        <Page title="Apply Card">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Apply for NFC Card
                    </Typography>
                </Stack>
                <Grid container>
                    {showWaitDialog === false ? (<Grid item xs={12} sm={12} md={6} mdOffset={3} sx={{ p: 2 }}>
                        {/* <Stack spacing={1}>
                            <Typography variant="body1">
                                Please choose a service
                            </Typography>
                            <Grid container display={"flex"} direction={"row"} spacing={2}>
                                {serviceList.length > 0 && serviceList.map((item, idx) => (<Grid key={idx} item md={3} sm={3} xs={6} onClick={() => { handleServiceChoose(item) }}>
                                    <Card sx={{
                                        border: selectedService?.id === item.id ? 2 : 0,
                                        ":hover": { boxShadow: 20 }, "cursor": "pointer"
                                    }}>
                                        <CardMedia
                                            component="img"
                                            height="100"
                                            width="100"
                                            image={item.image}
                                            alt="Paella dish"
                                            sx={{
                                                padding: "0.5em",
                                                objectFit: "contain",
                                            }}
                                        />
                                        <Box display={"flex"} direction={"row"} justifyContent={"center"} alignItems={"center"}>
                                            {item.display_name && <Typography sx={{ fontSize: 12, fontWeight: "bold" }} gutterBottom>{item.display_name}</Typography>}
                                        </Box>
                                    </Card>
                                </Grid>))}
                            </Grid>
                            <FormHelperText size="small" error={applyForm.errors.service_type}>{applyForm.errors?.service_type}</FormHelperText>
                        </Stack> */}
                        <Stack mt={3} spacing={1}>
                            <Typography variant="h6">
                                Please fill up the form
                            </Typography>
                            <Grid container display={"flex"} direction={"column"} spacing={2}>
                                <Grid item md={12} xs={12} sm={12}>
                                    <CustomAutocomplete label={"Choose Service"} optionLabel={'display_name'} options={serviceList ?? []} onUpdate={(val) => { handleServiceChoose(val) }} />
                                    <FormHelperText size="small" error={applyForm.errors.service_type}>{applyForm.errors?.service_type}</FormHelperText>
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    <CustomAutocomplete label={"Choose Merchant"} options={orgServiceList ?? []} onUpdate={(val) => { handleProviderChange(val) }} />
                                    <FormHelperText size="small" error={applyForm.errors.service_id}>{applyForm.errors?.service_id}</FormHelperText>
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    <CustomAutocomplete label={"Choose Payment Method"} options={orgMerchantList ?? []} onUpdate={(val) => { handleMerchantChange(val) }} />
                                    <FormHelperText size="small" error={applyForm.errors.service_merchant}>{applyForm.errors?.service_merchant}</FormHelperText>
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Recieve card at</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="delivery_method"
                                            id='delivery_method'
                                            value={applyForm.values.delivery_method}
                                            onChange={applyForm.handleChange}
                                        >
                                            <FormControlLabel value="Home" control={<Radio />} label="At home" />
                                            <FormControlLabel value="On-site" control={<Radio />} label="On-site" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack mt={2}>
                            <Grid container>
                                <Grid item md={12}>
                                    <LoadingButton fullWidth size="medium" type="button" onClick={applyForm.handleSubmit} variant="contained" loading={applyForm.isSubmitting} >
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>) : (
                        <Grid item xs={12} sm={12} md={6} mdOffset={3} sx={{ p: 2 }}>
                            <Typography variant='h4'>Please complete bKash auto debit consent journey ...</Typography>
                        </Grid>
                    )}
                </Grid>
            </Container>
            <WaitDialog open={showWaitDialog} url={url} />
            <UpdateAddressDialog open={showAddressUpdateDialog} onClose={() => { setShowAddressUpdateDialog(false) }} />
        </Page>
    );
}
