import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
// material
import { styled } from '@mui/system';
//
import { useDispatch, useSelector } from 'react-redux';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { API, TOKEN } from '../../Config';
import { resetState } from '../../store/slices/AuthSlice';
import { fetchConfig } from '../../store/slices/ConfigSlice';
import { fetchMyCard } from '../../store/slices/MyCardSlice';
import { fetchTransactionsThunk } from '../../store/slices/TransactionSlice';
import { hasGroup } from '../../helper';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100vh',
    maxHeight: '100vh',
    paddingTop: APP_BAR_MOBILE + 5,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const history = useNavigate()
    const [open, setOpen] = React.useState(false);
    const [isTimeout, setIsTimeout] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const loggedIn = localStorage.getItem(TOKEN)
    const loggedIn = useSelector(state => state.myDetails.data !== null)
    
    const onIdleState=(e)=>{
        // alert('hi, you became idle')
        localStorage.clear();
        navigate('/login');
    }
    useIdleTimer({ disabled: !loggedIn, timeout: 300000,onIdle: onIdleState})

    const handleLogout = () => {
        API.get('auth/logout/').then((res) => {
            dispatch(resetState())
            setIsTimeout(true);
            sessionStorage.clear()
            localStorage.clear()
            console.log('timed out')
            history('/login')

        }).catch(err => {
            dispatch(resetState())
            setIsTimeout(true);
            sessionStorage.clear()
            localStorage.clear()
            console.log('timed out')
            history('/login')
        })
    };
    React.useEffect(() => {
        // const isAuthenticated = localStorage.getItem("TOKEN")?true:false
        dispatch(fetchConfig());
        dispatch(fetchMyCard());
        dispatch(fetchTransactionsThunk());
    }, [dispatch])
    React.useEffect(() => {
        // const isAuthenticated = localStorage.getItem("TOKEN")?true:false
        if (localStorage.getItem(TOKEN) === undefined || localStorage.getItem(TOKEN) === null) {
            history('/login')
        }
        // else{
        //     history('/dashboard/transactions/list');
        // }
    }, [history])
    // React.useEffect(() => {
    //     start()
    // }, [])
    return (
        <RootStyle>
            <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}
