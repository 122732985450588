import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/system';
import { Card, Link, Container, Typography, Avatar } from '@mui/material';
// hooks
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';
import { TOKEN } from '../Config';

// import OTPLoginForm from '../sections/auth/login/OTPLoginForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
    // justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems:'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
    const {t,i18n} = useTranslation();
    const history = useNavigate()
    const location = useLocation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');
    React.useEffect(() => {
        // const isAuthenticated = localStorage.getItem("TOKEN")?true:false
        if (localStorage.getItem(TOKEN) === undefined || localStorage.getItem(TOKEN) === null) {
            history('/login')
        }
        else {
            history('/dashboard/my-card')
        }
    }, [history])
    useEffect(() => {
        if (location.state?.message) {
            enqueueSnackbar(location.state?.message, {
                variant: location.state?.type ? location.state?.type : "success",
            });
        }
    }, [])

    const getLocalizedLogo=()=>{
        const lang = i18n.language
        return lang==='bn'? String(process.env.PUBLIC_URL).concat("/static/images/bkash_bn.png") : String(process.env.PUBLIC_URL).concat("/static/images/bkash_en.png")
    }

    return (
        <>
            <Page title="Login">
                <RootStyle>
                    <HeaderStyle>
                        {/* <Logo /> */}
                        {/* <CMSLogo/> */}

                        {smUp && (
                            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                                {t('login.form.reg_text')} {''}
                                <Link variant="subtitle2" component={RouterLink} to="/register">
                                {t('login.form.reg_link_text')}
                                </Link>
                            </Typography>
                        )}
                    </HeaderStyle>

                    {mdUp && (
                        <SectionStyle>
                            {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                                Welcome to
                            </Typography> */}
                            {/* <img src="/static/illustrations/illustration_login.png" alt="login" /> */}
                            <img height={100} loading='lazy' src={getLocalizedLogo()} alt="login" />
                            {/* <Typography mt={1} sx={{marginLeft:'-30px'}} variant='poster' fontFamily={'Comic Sans MS, Comic Sans, cursive'}>Card Management System</Typography> */}
                        </SectionStyle>
                    )}

                    <Container maxWidth="sm">
                        <ContentStyle>
                            {!mdUp && <Avatar sx={{ height: 90,width:240, alignSelf: "center", borderRadius: 0,mb:4 }} src={String(process.env.PUBLIC_URL).concat("/static/images/bkash_en.png")} alt="login" />}

                            {/* <Typography variant="h3" >
                                Welcome to CMS
                            </Typography> */}
                            <Typography variant="h4" gutterBottom>
                                {t('login.form.header')}
                            </Typography>

                            <Typography sx={{ color: 'text.secondary', mb: 5 }}>{t('login.form.sub_header')}</Typography>

                            {/* <AuthSocial /> */}

                            <LoginForm />
                            {!smUp && <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                                {t('login.form.reg_text')}{' '}
                                <Link variant="subtitle2" component={RouterLink} to="/register">
                                {t('login.form.reg_link_text')}
                                </Link>
                            </Typography>}

                            {/* {!smUp && (
                                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                                    Are you a merchant? {''}
                                    <Link variant="subtitle2" component={RouterLink} to="/login/merchant">
                                        Login Here
                                    </Link>
                                </Typography>
                            )} */}
                        </ContentStyle>
                    </Container>
                </RootStyle>
            </Page>
        </>
    );
}
