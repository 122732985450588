import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PUBLIC_API } from '../../Config'

const initialState = {
    data: [],
    status: 'idle',
    error: ''
}

export const fetchGeoDataThunk = createAsyncThunk('geoData/', async () => {
    // const response = await JsonClient.get('geodata/')
    const response = await PUBLIC_API.get("geodata/")
    return response.data
})

export const geoDataSlice = createSlice({
    name: 'geoData',
    initialState,
    reducers: {
        resetState: (state) => {
            console.log('dispatching ----- ')
            state.data = {}
        },
    },
    extraReducers: {
        [fetchGeoDataThunk.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchGeoDataThunk.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.data = action.payload.data
        },
        [fetchGeoDataThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetState } = geoDataSlice.actions
export default geoDataSlice.reducer
