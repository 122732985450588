import { filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
// material
import {
    Stack,
    Button,
    Container,
    Typography,
    Box, InputAdornment, TextField, Switch, FormControlLabel,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// components
import Iconify from '../../components/Iconify';
import LoaderDialog from '../../components/LoaderDialog';
import { API } from '../../Config';
import Page from '../../components/Page';
import { fetchOrgDeviceListThunk, updateState } from '../../store/slices/POSDeviceSlice';
import CustomSwitch from '../../components/CustomSwitch';
import CustomDialog from '../../components/CustomDialog';



export default function POSManagement() {
    const orgDevices=useSelector(state=>state.orgDevices);
    const dispatch = useDispatch();
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 475,
            renderCell: (params) => (
                <Typography variant='span' sx={{ fontWeight: 'bold' }}>{params.row.name}</Typography>
            )
        },
        {
            field: 'purpose',
            headerName: 'Merchant Name',
            width: 475,
            renderCell: (params) => (
                <Typography variant='span' sx={{ fontWeight: 'bold' }}>{params.row.merchant_name}</Typography>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 200,
            headerAlign:'center',
            align:'center',
            renderCell: (params) => (
                <CustomSwitch prefix={"Inactive"} suffix={"Active"} showLabels={false} checked={params.row.status === 'ACTIVE'} onChange={(event) => setDeviceToChangeStatus(params.row)}/>
            )
        }
    ];
    const [transactions, setTransactions] = useState([]);
    const [deviceToChangeStatus,setDeviceToChangeStatus]=useState(null);


    useEffect(() => {
        if(orgDevices.status==='idle'){
            dispatch(fetchOrgDeviceListThunk());
        }
    }, [orgDevices.status]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [searchText, setSearchText] = useState()
    // op codes
    const [showLoaderDialog, setShowLoaderDialog] = useState(false);
    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            setTransactions(transactions.filter((item, idx) => String(item.card_number).includes(searchText)))
        }
    }

    const toggleStatus = () => {
        API.get(`pos/toggle-status/${deviceToChangeStatus.id}/`).then(res => {
            enqueueSnackbar('POS device status updated', {
                variant: "success",
            });
            dispatch(updateState(res.data.data));
            setDeviceToChangeStatus(null);
        }).catch(err => {
            enqueueSnackbar(err.response.data.message || err.message, {
                variant: "error",
            });
        })
    }
    

    return (
        <>
            <Page title="Pos Management">
                <LoaderDialog open={showLoaderDialog} url={null} />
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            POS Management
                        </Typography>

                    </Stack>

                    <Box>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <TextField
                                size='small'
                                autoFocus
                                fullWidth
                                disableUnderline
                                placeholder="Search…"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyPress={handleKeyPress}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                        </InputAdornment>,
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Button size='small' variant="outlined" onClick={() => { }}>
                                                Search
                                            </Button>
                                        </InputAdornment>
                                }}
                                sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                            />

                        </Stack>
                        <DataGrid
                            autoHeight {...transactions}
                            disableSelectionOnClick
                            rows={orgDevices.data||[]}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            density="comfortable"
                            // checkboxSelection
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            onRowClick={(rowData) => { console.log(rowData.row) }}
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                                    outline: "none !important",
                                    alignContent:'center'
                                },
                                "& .MuiDataGrid-cell.MuiDataGrid-cell--textCenter":{
                                    display:'flex !important',
                                    alignContent:'center !important',
                                }
                            }}
                        />
                    </Box>
                </Container>
                <CustomDialog
                    open={deviceToChangeStatus!==null}
                    title={"POS Device change status"}
                    contentText="Are you sure that you want to change this device status?"
                    onConfirm={(val) => { toggleStatus() }}
                    onClose={(val) => { setDeviceToChangeStatus(null) }}
                />
            </Page>
        </>
    );
}

