import * as React from 'react';
import { CardMedia } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from '../../../components/Iconify';



export default function WaitDialog(props) {
    const { open, onClose, onUpdate, url, loaderImage } = props
    const handleClose = () => {
        onClose(false);
    };
    React.useEffect(() => {
        if (open && url) {
            window.location.replace(url);
        }
    }, [open])
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                {/* <DialogTitle>Redirecting to payment gateway portal</DialogTitle> */}
                <DialogContent>
                    {loaderImage ? (<img width={100} height={100} alt='loading' src={loaderImage} />) : 
                    (<><DialogContentText>
                        Please wait a bit
                    </DialogContentText>
                    <Iconify icon={"line-md:loading-alt-loop"}/></>)
                    }

                </DialogContent>

                {/* <DialogActions>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <LoadingButton disabled={!updateForm.isValid} type="button" loading={updateForm.isSubmitting} onClick={updateForm.handleSubmit}>Update</LoadingButton>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}