import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
// material
import {
    Stack, TextField,
    Container,
    Typography, Box
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { API } from '../../Config';
// import { Stack, IconButton, InputAdornment, TextField, MenuItem, Select, InputLabel, Box } from '@mui/material';
// components
import Page from '../../components/Page';
import LoaderDialog from '../../components/LoaderDialog';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export default function AddCustomer() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);
    const [operatorDetails, setOperatorDetails] = useState({});
    const [showLoaderDialog, setShowLoaderDialog] = useState(false)

    useEffect(() => {
        setShowLoaderDialog(true)
        API.get('org/operator/details/').then(res => {
            console.log('op details',res.data.data)
            setOperatorDetails(res.data.data)
            setShowLoaderDialog(false)
        }).catch(err=>{
            console.log(err)
        })
    }, [])

    const RegisterSchema = Yup.object().shape({
        first_name: Yup.string().required('First name required'),
        last_name: Yup.string(),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required').min(8),
        phone: Yup.string().required('Phone is required').min(11).max(11),
        payment_service: Yup.string().required('Payment Service is required'),
    });
    const defaultValues = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        payment_service: '1',
    };
    const addCustomer = async (values, { setSubmitting, setErrors, resetForm }) => {
        console.log(values)
        await API.post('org/customer/add/', values).then(res => {
            setSubmitting(false)
            console.log(res.data)
            resetForm()
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
        }).catch(err => {
            setSubmitting(false)
            console.log(err.response?.data?.error_detail)
            if (err.response.data.errors) {
                setErrors(err.response.data.errors)
            }
        })
        // navigate('/dashboard', { replace: true });
    };
    const addForm = useFormik(
        {
            initialValues: defaultValues,
            validationSchema: RegisterSchema,
            validateOnBlur: false,
            validateOnChange: true,
            onSubmit: addCustomer
        }
    )
    const changePhone = (e) => {
        addForm.handleChange(e)
        addForm.setFieldValue('password', e.target.value)
    }
    return (
        <Page title="Add Customer">
            <LoaderDialog open={showLoaderDialog} url={null} />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Customer Registration
                    </Typography>
                </Stack>
                <Box sx={(theme) => ({
                    width: '50%',
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    },
                })}>
                    <Stack spacing={3}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                            <TextField
                                size='small'
                                fullWidth
                                required
                                id="first_name"
                                name="first_name"
                                value={addForm.values.first_name}
                                onChange={addForm.handleChange}
                                error={!!addForm.errors.first_name}
                                helperText={addForm.errors?.first_name}
                                placeholder="type your first name"
                                label="First Name"
                            />
                            <TextField
                                size='small'
                                fullWidth
                                id="last_name"
                                name="last_name"
                                value={addForm.values.last_name}
                                onChange={addForm.handleChange}
                                error={!!addForm.errors.last_name}
                                helperText={addForm.errors?.last_name}
                                placeholder="type your last name"
                                label="Last Name"
                            />
                        </Stack>
                        <FormControl>
                            <TextField
                                size='small'
                                fullWidth
                                required
                                id="email"
                                name="email"
                                value={addForm.values.email}
                                onChange={addForm.handleChange}
                                error={!!addForm.errors.email}
                                helperText={addForm.errors?.email}
                                placeholder="type your email"
                                label="Email"
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                size='small'
                                fullWidth
                                required
                                id="phone"
                                name="phone"
                                value={addForm.values.phone}
                                onChange={changePhone}
                                error={!!addForm.errors.phone}
                                helperText={addForm.errors?.phone}
                                placeholder="type your phone"
                                label="Phone"
                            />
                        </FormControl>
                        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5}>
                            <FormControl fullWidth>
                                <InputLabel id="vehicle_type">Vehicle Type</InputLabel>
                                <Select
                                    size='small'
                                    labelId="vehicle_type"
                                    id="vehicle_type"
                                    name="vehicle_type"
                                    value={addForm.values.vehicle_type}
                                    onChange={addForm.handleChange}
                                    label="Vehicle Type"
                                >
                                    <MenuItem value="1">
                                        <em>Car</em>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                size='small'
                                fullWidth
                                id="vehicle_reg_number"
                                name="vehicle_reg_number"
                                value={addForm.values.vehicle_reg_number}
                                onChange={addForm.handleChange}
                                error={!!addForm.errors.vehicle_reg_number}
                                helperText={addForm.errors?.vehicle_reg_number}
                                placeholder="vehicle registration number"
                                label="Vehicle Reg Number"
                            />
                        </Stack>
                        <FormControl fullWidth>
                            <InputLabel id="payment_service">Payment Method</InputLabel>
                            <Select
                                size='small'
                                labelId="payment_service"
                                id="payment_service"
                                name="payment_service"
                                value={addForm.values.payment_service}
                                onChange={addForm.handleChange}
                                label="Payment Method"
                            >
                                <MenuItem value="1">
                                    <em>bKash</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <TextField
                                size='small'
                                fullWidth
                                id="password"
                                name="password"
                                value={addForm.values.password}
                                onChange={addForm.handleChange}
                                error={!!addForm.errors.password}
                                helperText={addForm.errors?.password}
                                label="Password"
                                placeholder='Type your password'
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl> */}

                        <LoadingButton fullWidth size="medium" type="button" onClick={addForm.handleSubmit} variant="contained" loading={addForm.isSubmitting}>
                            Add Customer
                        </LoadingButton>
                    </Stack>
                </Box>
            </Container>
        </Page>
    );
}
