import * as React from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography, Slide } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { API } from '../../Config';
import { fetchUserDetail } from '../../store/slices/AuthSlice';
import store from '../../store/Store';


const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog(props) {
    const { open, onClose, onUpdate } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userDetail = useSelector(state => state.myDetails.data)
    const dispath = useDispatch()
    const [postalCodes, setPostCodes] = React.useState([])
    const [cities, setCities] = React.useState([])
    const [upazilas, setUpazilas] = React.useState([])
    const states = useSelector(state => state.geoData.data.divisions)

    const updateProfile = (values, { setSubmitting, setErrors, resetForm }) => {
        API.post("auth/user/profile/update/", values).then(res => {
            setSubmitting(false)
            console.log('update res', res.data)
            resetForm()
            onClose(false)
            dispath(fetchUserDetail())
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
        }).catch(err => {
            console.log(err.response)
            setSubmitting(false)
        })
    }
    const updateForm = useFormik({
        initialValues: {
            first_name: userDetail.first_name,
            last_name: userDetail.last_name,
            contact_phone: userDetail.contact_phone,
            address_line_1: userDetail.user_address?.address_line_1 ? userDetail.user_address.address_line_1 : '',
            address_line_2: userDetail.user_address?.address_line_2 ? userDetail.user_address?.address_line_2 : '',
            city: userDetail.user_address?.city ? userDetail.user_address.city : '',
            upazila: userDetail.user_address?.upazila ? userDetail.user_address.upazila : '',
            post_code: userDetail.user_address?.post_code ? userDetail.user_address.post_code : '',
            state: userDetail.user_address?.state ? userDetail.user_address.state : '',
        },
        validationSchema: Yup.object().shape({
            first_name: Yup.string().required('First Name is must'),
            last_name: Yup.string(),
            contact_phone: Yup.string().nullable().max(11).min(11),
            address_line_1: Yup.string().required("Address is must"),
            address_line_2: Yup.string(),
            city: Yup.string().required("City is must"),
            upazila: Yup.string().required("Thana is must"),
            state: Yup.string().nullable(),
            post_code: Yup.string(),
        }),
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: updateProfile
    })

    React.useEffect(() => {
        if (open && userDetail !== undefined) {
            setCities(Array.from(store.getState().geoData.data.districts));
            updateForm.setValues({
                first_name: userDetail.first_name,
                last_name: userDetail.last_name,
                contact_phone: userDetail.contact_phone,
                address_line_1: userDetail.user_address?.address_line_1 ? userDetail.user_address.address_line_1 : '',
                address_line_2: userDetail.user_address?.address_line_2 ? userDetail.user_address?.address_line_2 : '',
                city: userDetail.user_address?.city ? userDetail.user_address.city : '',
                upazila: userDetail.user_address?.upazila ? userDetail.user_address.upazila : '',
                post_code: userDetail.user_address?.post_code ? userDetail.user_address.post_code : '',
                state: userDetail.user_address?.state ? userDetail.user_address.state : '',
            })
            if (userDetail.user_address !== null) {
                setUpazilas(Array.from(store.getState().geoData.data.upazilas).filter(e => e.district_id === String(Array.from(store.getState().geoData.data.districts).find(e => e.name === userDetail.user_address?.city).id)))
                const div = String(Array.from(store.getState().geoData.data.districts).find(e => e.name === userDetail.user_address?.city).id)
                console.log('user div', div)
                Array.from(store.getState().geoData.data.districts).forEach((item, idx) => {
                    if (item.name === "Dhaka Metro") {
                        console.log('dis', item)
                    }
                })

                // console.log(Array.from(store.getState().geoData.data.districts).filter(e => e.division_id === String(Array.from(states).find(e => e.name === userDetail.user_address?.state).id)))
                // setUpazilas(Array.from(store.getState().geoData.data.districts).filter(e => e.district_id === String(Array.from(cities).find(e => e.name === userDetail.user_address?.city).id)))
                // setPostCodes(Array.from(store.getState().geoData.data.postcodes).filter(e => e.district_id === String(Array.from(store.getState().geoData.data.districts).find(e => e.name === userDetail.user_address?.city).id)))
            }

        }
    }, [open, userDetail])
    const handleClose = () => {
        onClose(false);
    };

    // const onStateChange = (event) => {
    //     updateForm.setValues({ ...updateForm.values, 'state': event.target.value, 'city': '', 'post_code': '', 'upazila': '' })
    //     setCities(Array.from(store.getState().geoData.data.districts).filter(e => e.division_id === String(states.find(e => e.name === event.target.value).id)))
    // }
    const onCityChange = (event) => {
        updateForm.setValues({ ...updateForm.values, 'city': event.target.value, 'post_code': '', 'upazila': '' })
        setUpazilas(Array.from(store.getState().geoData.data.upazilas).filter(e => e.district_id === String(cities.find(e => e.name === event.target.value).id)))
    }
    const onUpazilaChange = (event) => {
        updateForm.setValues({ ...updateForm.values, 'upazila': event.target.value, 'post_code': '' })
        // setPostCodes(Array.from(store.getState().geoData.data.postcodes).filter(e => e.district_id === String(cities.find(e => e.name === event.target.value).id)))
    }
    const onPostCodeChange = (event) => {
        updateForm.setValues({ ...updateForm.values, 'post_code': event.target.value })
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>Update Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You can update below information. To update card information please contact support.
                    </DialogContentText>
                    <Typography variant="body2" mt={2}>
                        Profile Information
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="first_name"
                                name="first_name"
                                label="First Name"
                                type="text"
                                fullWidth
                                required
                                variant="standard"
                                value={updateForm.values.first_name}
                                onChange={updateForm.handleChange}
                                error={!!updateForm.errors.first_name && updateForm.touched.first_name}
                                helperText={updateForm.touched.first_name?updateForm.errors?.first_name:undefined}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <TextField
                                margin="dense"
                                id="last_name"
                                name="last_name"
                                label="Last Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={updateForm.values.last_name}
                                onChange={updateForm.handleChange}
                                error={!!updateForm.errors.last_name && updateForm.touched.last_name}
                                helperText={updateForm.touched.last_name? updateForm.errors?.last_name:undefined}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <TextField
                                margin="dense"
                                id="contact_phone"
                                name="contact_phone"
                                label="Contact Phone"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={updateForm.values.contact_phone}
                                onChange={updateForm.handleChange}
                                error={!!updateForm.errors.contact_phone && updateForm.touched.contact_phone}
                                helperText={updateForm.touched.contact_phone? updateForm.errors?.contact_phone:undefined}
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="body2" mt={3}>
                        Address Information
                    </Typography>
                    <Stack spacing={2} display="flex" direction={"column"} >
                        <FormControl fullWidth size='small'>
                            <TextField
                                fullWidth
                                required
                                variant="standard"
                                margin="dense"
                                id="address_line_1"
                                name="address_line_1"
                                value={updateForm.values.address_line_1}
                                onChange={updateForm.handleChange}
                                error={!!updateForm.errors.address_line_1 && updateForm.touched.address_line_1}
                                helperText={updateForm.touched.address_line_1? updateForm.errors?.address_line_1:undefined}
                                // helperText="We will use this address to mail you percels, please help us with valid information."
                                placeholder="type your address"
                                label="Address Line 1"
                                minRows={1}
                                multiline
                            />
                        </FormControl>
                        <FormControl fullWidth size='small'>
                            <TextField
                                margin="dense"
                                id="address_line_2"
                                name="address_line_2"
                                label="Address Line 2"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={updateForm.values.address_line_2}
                                onChange={updateForm.handleChange}
                                error={!!updateForm.errors.address_line_2 && updateForm.touched.address_line_2}
                                helperText={updateForm.touched.address_line_2? updateForm.errors?.address_line_2:undefined}
                                minRows={1}
                                multiline
                            />
                        </FormControl>
                        {/* <FormControl fullWidth size='small' error={!!updateForm.errors.state}>
                            <InputLabel id="state">Division</InputLabel>
                            <Select
                                labelId="state"
                                id="state"
                                name="state"
                                variant="standard"
                                value={updateForm.values.state}
                                onChange={onStateChange}
                                label="Division"
                            >
                                {states?.length > 0 && states.map((item, idx) => (
                                    <MenuItem value={item.name} key={idx}>
                                        <em>{item.name}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{updateForm.errors?.state}</FormHelperText>
                        </FormControl> */}
                        <FormControl required fullWidth size='small' error={!!updateForm.errors.city && updateForm.touched.city}>
                            <InputLabel id="city">District</InputLabel>
                            <Select
                                labelId="city"
                                id="city"
                                name="city"
                                variant="standard"
                                value={updateForm.values.city}
                                onChange={onCityChange}
                                label="District"
                                error={!!updateForm.errors.city && updateForm.touched.city}
                            >
                                {cities.length > 0 && cities.map((item, idx) => (
                                    <MenuItem value={item.name} key={idx}>
                                        <em>{item.name}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                            {updateForm.touched.city && <FormHelperText>{updateForm.errors?.city}</FormHelperText>}
                        </FormControl>
                        <FormControl required fullWidth size='small' error={!!updateForm.errors.upazila && updateForm.touched.upazila}>
                            <InputLabel id="upazila">Thana</InputLabel>
                            <Select
                                labelId="upazila"
                                id="upazila"
                                name="upazila"
                                variant="standard"
                                value={updateForm.values.upazila}
                                onChange={onUpazilaChange}
                                label="Thana"
                            >
                                {upazilas.length > 0 && upazilas.map((item, idx) => (
                                    <MenuItem value={item.name} key={idx}>
                                        <em>{item.name}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{updateForm.errors?.upazila}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth size='small' error={!!updateForm.errors.post_code && updateForm.touched.post_code}>
                            {/* <InputLabel id="post_code">Post Code</InputLabel>
                            <Select
                                labelId="post_code"
                                id="post_code"
                                name="post_code"
                                variant="standard"
                                value={updateForm.values.post_code}
                                onChange={onPostCodeChange}
                                label="Post Code"
                            >
                                {postalCodes.length > 0 && postalCodes.map((item, idx) => (
                                    <MenuItem value={item.postCode} key={idx}>
                                        <em>{String(item.postCode).concat(' - ').concat(item.postOffice)}</em>
                                    </MenuItem>
                                ))}
                            </Select> */}
                            {/* <FormHelperText>{updateForm.errors?.post_code}</FormHelperText> */}
                            <TextField
                                autoFocus
                                margin="dense"
                                id="post_code"
                                name="post_code"
                                label="Post Code"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={updateForm.values.post_code}
                                onChange={updateForm.handleChange}
                                error={!!updateForm.errors.post_code}
                                helperText={updateForm.errors?.post_code}
                            />
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='error' type='button' onClick={handleClose}>Cancel</Button>
                    <LoadingButton variant='contained' disabled={!updateForm.isValid} type="button" loading={updateForm.isSubmitting} onClick={updateForm.handleSubmit}>Update</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}