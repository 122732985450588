import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material
import {
    Stack, IconButton, Tooltip, Zoom,
    Container, Card,
    Typography, Box, CardContent
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSnackbar } from 'notistack';
import useResponsive from "../../hooks/useResponsive";
import Page from '../../components/Page';
import { fetchMyServices } from '../../store/slices/MyServicesSlice';
import Iconify from '../../components/Iconify';
import CustomSwitch from '../../components/CustomSwitch';
import { fetchMyCard } from '../../store/slices/MyCardSlice';
import { API } from '../../Config';
import ConfirmationDialog from './inc/ConfirmationDialog';
import CardEnableDialog from './inc/CardEnableDialog';


const MyCardDetail = ({ color = 'primary', ...other }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const myCard = useSelector(state => state.myCard.data)
    const myServices = useSelector(state => state.myServices.data)


    // component states
    const [showActivationDialog,setShowActivationDialog]=useState(false)
    const [showDisableConfirmDialog,setShowDisableConfirmDialog]=useState(false) // confirmation dialoag open-close state
    const [showUnbindConfirmDialog,setShowUnbindConfirmDialog]=useState(false)
    const [serviceToRemove,setServiceToRemove]=useState(null)

    const handleCardStatusChange=(val)=>{
        if(!val){
            setShowDisableConfirmDialog(true)
        }else{
            setShowActivationDialog(true)
        }
    }
    useEffect(() => {
        dispatch(fetchMyServices())
        dispatch(fetchMyCard())
    }, [])
    const disableCard=(val)=>{
        if(val){
            API.get("nfccards/card/disable/").then(res=>{
                if(res.statusText==="OK"){
                    setShowDisableConfirmDialog(false)
                    dispatch(fetchMyCard())
                    enqueueSnackbar(res.data.message, {
                        variant: "success",
                    });
                }
            }).catch(err=>{
                console.log(err.response.message)
            })
        }
    }

    const removeBinding=()=>{
        API.get("nfccards/card/binding/remove/".concat(serviceToRemove.id).concat("/")).then(res=>{
            if(res.statusText==="OK"){
                setShowUnbindConfirmDialog(false)
                dispatch(fetchMyServices())
                enqueueSnackbar(res.data.message,{variant:"success"})
            }
        }).catch(err=>{
            enqueueSnackbar(err.response?.message,{variant:"error"})
            setShowUnbindConfirmDialog(false)
        })
    }
    
    return (
        <>
            <Page title="NFC Card detail">
                <Container>
                    <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" mb={smUp ? 5 : 2}>
                        <Typography variant="h4" gutterBottom>
                            My Card detail
                        </Typography>
                    </Stack>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} mdOffset={3}>
                            <Stack spacing={1.5}>
                                <Card
                                    sx={{
                                        // py: !hasCard ? 5 : 0,
                                        boxShadow: 5,
                                        borderRadius: 2,
                                        color: (theme) => theme.palette.primary.main,
                                        background: 'none',
                                        bgcolor: (theme) => theme.palette.secondary.lighter,
                                    }}
                                >
                                    <CardContent>
                                        <Box display="flex" flexDirection="column" justifyContent="flex-start">
                                            {/* <Typography variant="h5" gutterBottom>
                                            Card details
                                        </Typography> */}
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                                Provider Organization
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {myCard?.provider}
                                            </Typography>
                                            <Typography variant="body2" color="text.primary">
                                                Expires at <b>{myCard?.expires_at}</b>
                                            </Typography>
                                            <Typography variant="body2" color="text.primary">
                                                Status : {myCard?.status}
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        // py: !hasCard ? 5 : 0,
                                        boxShadow: 5,
                                        borderRadius: 2,
                                        color: (theme) => theme.palette.primary.main,
                                        background: 'none',
                                        bgcolor: (theme) => theme.palette.secondary.lighter,
                                    }}
                                >
                                    <CardContent>
                                        <Box display="flex" flexDirection="column" justifyContent="flex-start">
                                            <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems={"center"}>
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    Binded services {`(${(myServices && myServices.length>0)?myServices.length:0})`}
                                                </Typography>
                                                
                                                <Tooltip title="Add Service" arrow TransitionComponent={Zoom} placement="left">
                                                    <IconButton type='button' onClick={() => { navigate("/dashboard/my-card/add-service") }} variant="contained" color="primary" size='medium'>
                                                        <Iconify icon={"line-md:plus"} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>

                                            {myServices && myServices.length > 0 && myServices.map((item, idx) => (
                                                <Grid key={idx} container display={"flex"} flexDirection="row" justifyContent={"space-between"}>
                                                    <Grid item xs={10} display={"flex"} flexDirection={"row"} justifyContent="flex-start" alignItems={"center"}>
                                                        <Typography variant='body1'>{item.service}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} display={"flex"} flexDirection={"row"} justifyContent="flex-end" alignItems={"center"}>
                                                        <Tooltip title="remove service" arrow TransitionComponent={Zoom} placement="left">
                                                            <IconButton variant="contained" color="primary" size='medium' type='button' onClick={()=>{setServiceToRemove(item);setShowUnbindConfirmDialog(true)}}>
                                                                <DeleteForeverIcon fontSize='medium' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        boxShadow: 5,
                                        borderRadius: 2,
                                        color: (theme) => theme.palette.primary.main,
                                        background: 'none',
                                        bgcolor: (theme) => theme.palette.secondary.lighter,
                                    }}
                                >
                                    <CardContent>
                                        <Box display="flex" flexDirection="column" justifyContent="flex-start">
                                            <Grid container display={"flex"} flexDirection="row" justifyContent={"space-between"}>
                                                <Grid item xs={8} display={"flex"} flexDirection={"row"} justifyContent="flex-start" alignItems={"center"}>
                                                    <Typography variant='body1'>Card Status</Typography>
                                                </Grid>
                                                <Grid item xs={4} display={"flex"} flexDirection={"row"} justifyContent="flex-end" alignItems={"center"}>
                                                    <CustomSwitch prefix={"Inactive"} suffix={"Active"} checked={myCard?.status==="ACTIVE"??false} onChange={handleCardStatusChange}/>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
                <ConfirmationDialog
                    open={showDisableConfirmDialog}
                    title="NFC Card disable confirmation"
                    contentText="Are you sure that you want to disable your NFC Card"
                    onConfirm={(val)=>{disableCard(true)}}
                    onClose={(val)=>{setShowDisableConfirmDialog(val)}}
                />
                <ConfirmationDialog
                    open={showUnbindConfirmDialog}
                    title={"Unbinding ".concat(serviceToRemove?.service)}
                    contentText="Are you sure that you want to unbind this service?"
                    onConfirm={(val)=>{removeBinding(val)}}
                    onClose={(val)=>{setShowUnbindConfirmDialog(false)}}
                />
                <CardEnableDialog
                    open={showActivationDialog}
                    title="Enable NFC Card"
                    cardNumber={myCard?.card_number}
                    onEnable={(val)=>{dispatch(fetchMyCard());setShowActivationDialog(false);}}
                    onClose={(val)=>{setShowActivationDialog(val)}}
                />
            </Page>
        </>
    )
}

export default MyCardDetail