import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../Config'

const initialState = {
    data: {},
    has_card: false,
    status: 'idle',
    error: ''
}

export const fetchMyCard = createAsyncThunk('nfccards/user/card/', async () => {
    const response = await API.get('nfccards/user/card/')
    console.log('myCard', response)
    return response.data
})

export const myCardSlice = createSlice({
    name: 'myCard',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = {}
        },
    },
    extraReducers: {
        [fetchMyCard.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchMyCard.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.data = action.payload.data
            state.has_card = action.payload.has_card
        },
        [fetchMyCard.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetState } = myCardSlice.actions
export default myCardSlice.reducer
