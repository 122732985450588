import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, FormControl } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { PUBLIC_API, TOKEN, USER_GROUPS } from '../../../Config';
import Iconify from '../../../components/Iconify';
import { hasGroup } from '../../../helper';
import { fetchOperatorDetail } from '../../../store/slices/OperatorSlice';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const {t,i18n} =useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch()

    const LoginSchema = Yup.object().shape({
        email_or_phone: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const defaultValues = {
        email_or_phone: '',
        password: '',
        // remember: true,
    };
    const methods = useForm({ resolver: yupResolver(LoginSchema), defaultValues });

    const { handleSubmit, formState: { isSubmitting } } = methods;

    const onSubmit = async () => {
        await PUBLIC_API.post('auth/login/email/', methods.getValues()).then(res => {
            console.log('login-data',res.data)
            if (res.data.status_code === 200) {
                localStorage.setItem(TOKEN, res.data.token)
                localStorage.setItem(USER_GROUPS, JSON.stringify(res.data.data.groups))
                if(hasGroup('Collector') || hasGroup('Organization Admin')){
                    dispatch(fetchOperatorDetail());
                    navigate('/dashboard/transactions/list', { replace: true });
                }
                else{
                    navigate('/dashboard/my-card', { replace: true });
                }
            }
        }).catch(err => {
            console.log(err)
            // enqueueSnackbar(err.message, {
            //     variant: "warning",
            // });
            if(err.response?.data?.message){
                enqueueSnackbar(err.response?.data?.message, {
                    variant: "warning",
                });
            }
            else{
                enqueueSnackbar(err.message, {
                    variant: "warning",
                });
            }
        })
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <RHFTextField name="email_or_phone" label={t('login.form.username_label')} variant="standard" />
                <RHFTextField
                    name="password"
                    label={t('login.form.password_label')}
                    variant="standard"
                    type={showPassword ? 'text' : 'password'}
                    size="sm"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') handleSubmit()
                    }}
                />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                {/* <RHFCheckbox name="remember" label="Remember me" /> */}
                <Link variant="subtitle2" underline="hover" component={RouterLink} to="/forget-pass">
                    {t('login.form.forgot_password')}
                </Link>
            </Stack>
            <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={isSubmitting}>
                {t('login.form.submit')}
            </LoadingButton>
        </FormProvider>
    );
}
