import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Chip from '@mui/material/Chip';
import Iconify from '../Iconify';


export default function Transaction({data}) {
    const theme = useTheme();
    const {pathname}=useLocation()
    
    React.useEffect(()=>{console.log(pathname)},[pathname])
    return (
        <Card sx={{ display: 'flex',mt:1}}>
            <Grid container>
                <Grid xs={3} sm={2} md={3} display="flex" justifyContent="center" alignItems="center">
                    <CardMedia
                        component="img"
                        image={data.merchant_logo}
                        alt={data.merchant_name}
                        sx={(theme) => ({
                            width: '80%',
                            cursor: 'pointer',
                            "&.MuiCardMedia-img": {
                                borderRadius: 1,
                                boxShadow:2
                            },
                        })}
                    />
                </Grid>
                {/* <Grid xs={6} sm={8} md={6} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    {pathname==="/dashboard/my-reward" && parseInt(data.service_detail?.used_point,10)>0 && <Typography sx={{ fontSize: 12,width: "100%",color:"red" }} color="text.secondary">
                        Points Redeemed ({parseInt(data.service_detail?.used_point,10)})
                    </Typography>}
                    {pathname==="/dashboard/my-reward" && parseInt(data.service_detail?.earned_points,10)>0 && <Typography sx={{ fontSize: 12,width: "100%",color:"green" }} color="text.secondary">
                        Points Earned ({parseInt(data.service_detail?.earned_points,10)})
                    </Typography>}
                    <Typography sx={{ fontSize: 14,width: "100%" }} color="text.secondary">
                        Payment made at
                    </Typography>
                    <Typography component="div" variant="body1" sx={{ width: "100%",fontSize: 12 }}>
                    {data.merchant_name}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom color="text.secondary" sx={{ width: "100%"}}>
                        {data.created_at}
                    </Typography>
                </Grid> */}
                <Grid xs={6} sm={8} md={6} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    {parseInt(data.service_detail?.used_point,10)>0 && <Typography sx={{ fontSize: 12,width: "100%",color:"red" }} color="text.secondary">
                        Points Redeemed ({parseInt(data.service_detail?.used_point,10)})
                    </Typography>}
                    {parseInt(data.service_detail?.earned_points,10)>0 && <Typography sx={{ fontSize: 12,width: "100%",color:"green" }} color="text.secondary">
                        Points Earned ({parseInt(data.service_detail?.earned_points,10)})
                    </Typography>}
                    <Typography sx={{ fontSize: 14,width: "100%" }} color="text.secondary">
                        Payment made at
                    </Typography>
                    <Typography component="div" variant="body1" sx={{ width: "100%",fontSize: 12 }}>
                    {data.merchant_name}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom color="text.secondary" sx={{ width: "100%"}}>
                        {data.created_at}
                    </Typography>
                </Grid>
                <Grid xs={3} sm={2} md={3} display="flex" justifyContent="flex-end" alignItems="center">
                    <Chip icon={<Iconify icon={"mdi:currency-bdt"} />} label={`${data.amount}`} color="primary" sx={{boxShadow:2,minWidth:80 }}/>
                </Grid>
            </Grid>
        </Card>
    );
}