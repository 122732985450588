import React from "react";
import { Container,Stack,Typography,TextField,Box,FormControl,InputAdornment } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import { useFormik } from "formik";
import { useSnackbar } from 'notistack';
import { PUBLIC_FORM_API,PUBLIC_API } from "../../Config";
import Page from '../../components/Page';
import useResponsive from "../../hooks/useResponsive";


export default function VerifyOTP({color="primary"}){
    const smUp = useResponsive('up', 'sm');
    const [sendingOTP,setSendingOTP]=React.useState(false)
    const [showTimer,setShowTimer]=React.useState(true)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const location=useLocation()
    const navigate = useNavigate()
    const onEnterKeyPress=(event)=>{
        if(event.key==="Enter"){
            verifyForm.handleSubmit()
        }
    }
    const sendOTP = () => {
        let phone=""
        let email=""
        const regData = JSON.parse(sessionStorage.getItem("reg_data"))
        Object.entries(regData).forEach((item, idx) => {
            if (item[1] !== null && item[1] !== undefined) {
              if(item[0]==="phone"){
                phone=item[1]
              }
              else if(item[0]==="email"){
                email=item[1]
              }
            }
        })
        setSendingOTP(true)
        PUBLIC_API.post('auth/user/otp/send/', { phone_number: phone,email }).then(res => {
          console.log(res.data)
          setShowTimer(true)
          setSendingOTP(false)
          enqueueSnackbar(res.data.message, {
            variant: "success",
          });
        }).catch(err => {
          console.log(err.response.data.message)
          enqueueSnackbar(err.response.data.message, {
            variant: "warning",
          });
          setSendingOTP(false)
        })
    }
    const OTPButton = () => (
        <LoadingButton type='button' variant='outlined' onClick={sendOTP} loading={sendingOTP} disabled={showTimer}>{showTimer===false?'Resend':'Try after a minute'}</LoadingButton>
    )
    const register = async (values, { setSubmitting, setErrors, resetForm }) => {
        const formData=new FormData()
        const regData = JSON.parse(sessionStorage.getItem("reg_data"))
        Object.entries(regData).forEach((item, idx) => {
            if (item[1] !== null && item[1] !== undefined) {
              formData.append(item[0], item[1])
            }
        })
        formData.append('otp',values.otp)
        if(location.state?.photo){
            formData.append("usr_profile_pic", location.state?.photo);
        }
        await PUBLIC_FORM_API.post('auth/registration/', formData).then(res => {
          setSubmitting(false)
          console.log(res.data)
          resetForm()
          enqueueSnackbar(res.data.message, {
            variant: "success",
          });
          navigate("/login")
        }).catch(err => {
          setSubmitting(false)
          console.log(err.response?.data?.error_detail)
          if (err.response?.data?.errors) {
            setErrors(err.response.data.errors)
          }
          enqueueSnackbar(err.response.data?.errors?.otp, {
            variant: "warning",
          });
        })
    };
    const verifyForm=useFormik({
        initialValues:{
            otp:""
        },
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: register
    })
    React.useEffect(() => {
        const regData = JSON.parse(sessionStorage.getItem("reg_data"))
        if(regData===undefined || regData===null){
            return navigate("/register")
        }
        const timeout=setTimeout(()=>{
          setShowTimer(false)
        }, 60000);
        
        return ()=>clearTimeout(timeout)
    }, []);
    React.useEffect(() => {
        let timeout=null
        
        if(showTimer){
          timeout=setTimeout(()=>{
            setShowTimer(false)
          }, 60000);
        }
        return ()=>clearTimeout(timeout)
      }, [showTimer]);
    return(
        <>
            <Page title="Verify OTP" sx={{marginTop:"200px"}}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} mdOffset={3}>
                            <Box>
                                <Stack spacing={2}>
                                    <Typography variant="h6">Please submit otp to verify your phone number</Typography>
                                    <FormControl >
                                        <TextField
                                            fullWidth
                                            required
                                            id="otp"
                                            name="otp"
                                            type="number"
                                            value={verifyForm.values.otp}
                                            onChange={verifyForm.handleChange}
                                            error={!!verifyForm.errors.otp}
                                            helperText={'Enter 6 digit OTP'}
                                            placeholder="type your OTP"
                                            label="OTP"
                                            onKeyDown={onEnterKeyPress}
                                            InputProps={{
                                                endAdornment:
                                                  <InputAdornment position="end">
                                                    <OTPButton />
                                                  </InputAdornment>
                                            }}
                                        />
                                    </FormControl>
                                    <LoadingButton fullWidth size="medium" type="button" onClick={verifyForm.handleSubmit} variant="contained" loading={verifyForm.isSubmitting}>
                                    Submit
                                    </LoadingButton>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    )
}