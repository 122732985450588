import * as React from 'react';
import { styled } from '@mui/system';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
                backgroundColor: theme.palette.primary.main,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        backgroundColor: theme.palette.primary.main,
        boxSizing: 'border-box',
    },
}));

export default function CustomSwitch({ prefix, suffix,checked,onChange, showLabels=true }) {
    const handleChange = (event) => {
        onChange(event.target.checked);
    };
    React.useEffect(()=>{
        console.log("**",checked)
    },[checked])
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {showLabels && <Typography fontSize={14}>{prefix}</Typography>}
            <AntSwitch color='primary' checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'ant design' }} />
            {showLabels && <Typography fontSize={14}>{suffix}</Typography>}
        </Stack>
    );
}