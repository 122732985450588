import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../Config'

const initialState = {
    data: [],
    status: 'idle',
    error: ''
}

export const fetchOrgDeviceListThunk = createAsyncThunk('device/list/', async () => {
    const response = await API.get('pos/device-list/')
    console.log('org', response)
    return response.data.data
})

export const orgDeviceSlice = createSlice({
    name: 'orgDeviceList',
    initialState,
    reducers: {
        resetState: (state) => {
            console.log('dispatching ----- ');
            state.data = [];
        },
        updateState:(state,action)=>{
            state.data=state.data.map((item)=>(item.id===action.payload.id?action.payload:item));
        }
    },
    extraReducers: {
        [fetchOrgDeviceListThunk.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchOrgDeviceListThunk.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.data = action.payload
        },
        [fetchOrgDeviceListThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetState,updateState } = orgDeviceSlice.actions
export default orgDeviceSlice.reducer
