import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// @mui
import { styled } from '@mui/system';
import { Card, Link,Badge,Avatar, Container,Stack, IconButton, InputAdornment, TextField, Box, Tooltip, Zoom,FormControl, Typography,Radio,FormControlLabel,RadioGroup,FormLabel,FormHelperText} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { PUBLIC_API, PUBLIC_FORM_API } from '../../Config';
import CustomDatePicker from '../../components/CustomDatePicker';
// sections

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 42,
    height: 42,
    border: `2px solid ${theme.palette.background.paper}`,
    background: theme.palette.primary.darker,
    cursor: "pointer"
  }));

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    // bottom:'215px',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');
    const {paramStr}=useParams()
    const [applyCardData,setApplyCardData]=useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);
    const [image, setImage] = useState(null);
    const [phone, setPhone] = useState();
    const pictureElement = useRef(null);
    const hiddenFileInput = React.useRef(null);
    const [timer, setTimer] = useState(0)
    const [showTimer, setShowTimer] = useState(false)
    const navigate = useNavigate()
    const [sendingOTP, setSendingOTP] = useState(false)

    function toClear() {
        // pictureElement.current.clearPictures();
    }
    const handlePhotoChangeClick = event => {
        // setShowPhotoDialog(true)
        hiddenFileInput.current.click();
    };
    const handlePhotoChange = event => {
        const fileUploaded = event.target.files[0];
        if (validatePhotoSize(fileUploaded)) {
            setImage(fileUploaded)
        }
        else {
            console.log("large file")
            enqueueSnackbar("Image size limit exceed (max:5MB)", {
                variant: "warning",
            });
        }
    };

    const validatePhotoSize = (file) => {
        const MAX_FILE_SIZE = 1024 // 5MB
        if (!file) {
            return false
        }
        const fileSizeKiloBytes = file.size / 1024
        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            return false
        }
        return true
    }

    const RegisterSchema = Yup.object().shape({
        first_name: Yup.string().required('First name required'),
        last_name: Yup.string(),
        email: Yup.string().email('Email must be a valid email address'),
        password: Yup.string().required('Password is required').min(8),
        phone: Yup.string().required('Phone is required').min(11).max(15),
        payment_service: Yup.string().required('Payment Service is required'),
        // address_line_1: Yup.string().required('Address is required'),
        // birth_date: Yup.string(),
        delivery_method: Yup.string().required('We want to know how will you recieve NFC Card')
        // otp: Yup.string().required('OTP is required'),
        // otp: Yup.string(),
    });

    const defaultValues = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        payment_service: '1',
        // address_line_1: '',
        birth_date: "",
        delivery_method:"On-site"
    };


    const register = async (values, { setSubmitting, setErrors, resetForm }) => {
        console.log(values)
        const formData = new FormData()
        Object.entries(values).forEach((item, idx) => {
            console.log(item)
            if (item[1] !== null && item[1] !== undefined) {
                formData.append(item[0], item[1])
            }
        })

        if (image !== null) {
            sessionStorage.setItem("reg_photo", image)
        }
        // sending OTP to user phone and redirecting to verify otp page
        sessionStorage.setItem("reg_data", JSON.stringify(values))
        await PUBLIC_API.post('auth/user/otp/send/', { phone: regForm.values.phone, email: regForm.values.email }).then(res => {
            console.log(res.data)
            setShowTimer(true)
            setSendingOTP(false)
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
            navigate('/verify-address', { replace: true, state: { photo: image,applyCardData,regData:values } });
        }).catch(err => {
            const errors = {}
            if (err.response.data.errors?.email) {
                errors.email = err.response.data.errors?.email[0]
            }
            if (err.response.data.errors?.phone) {
                errors.phone = err.response.data.errors?.phone[0]
            }
            setErrors(errors)
            enqueueSnackbar(err.response.data.message, {
                variant: "warning",
            });
        })

        // await PUBLIC_FORM_API.post('auth/registration/', formData).then(res => {
        //   setSubmitting(false)
        //   console.log(res.data)
        //   resetForm()
        //   setImage(null)
        //   toClear()
        //   enqueueSnackbar(res.data.message, {
        //     variant: "success",
        //   });
        //   navigate("/login")
        // }).catch(err => {
        //   setSubmitting(false)
        //   console.log(err.response?.data?.error_detail)
        //   if (err.response?.data?.errors) {
        //     setErrors(err.response.data.errors)
        //   }
        //   enqueueSnackbar(err.response.message, {
        //     variant: "warning",
        //   });
        // })

    };

    const regForm = useFormik({
        initialValues: defaultValues,
        validationSchema: RegisterSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: register
    })

    const onDrop = (picture) => {
        setImage(picture);
    };
    React.useEffect(() => {
        let timeout = null

        if (showTimer) {
            timeout = setTimeout(() => {
                setShowTimer(false)
            }, 60000);
        }
        return () => clearTimeout(timeout)
    }, [showTimer]);

    React.useEffect(() => {
        const params=String(paramStr).trim().split('-')
        if(params.length>0){
            const dataObj={
                "service_id":params[0],
                "service_type":params[1],
                "service_merchant":params[2],
                "platform":params[3]
            }
            setApplyCardData(dataObj)
            sessionStorage.setItem("card-apply-data",JSON.stringify(dataObj))
        }
    }, []);
    const handleOnChange = (value, data, event, formattedValue) => {
        setPhone(value)
        regForm.setFieldValue('phone', "+".concat(String(value)))
        // this.setState({ rawPhone: value.slice(data.dialCode.length) })
    }

    return (
        <Page title="Register And Apply">
            <RootStyle>
                <HeaderStyle>
                    {/* <PPSLogo /> */}
                    {smUp && (
                        <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                            Already have an account? {''}
                            <Link variant="subtitle2" component={RouterLink} to="/login">
                                Login
                            </Link>
                        </Typography>
                    )}
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        {/* <Typography variant="h3" sx={{ px: 5, mt: '-285px', mb: 5 }}>
                            Manage the cashless payment more effectively with CMS
                        </Typography>
                        <img alt="register" src={String(process.env.PUBLIC_URL).concat("/static/illustrations/illustration_register.png")} /> */}
                        <img height={200} width={200} loading='lazy' src={String(process.env.PUBLIC_URL).concat("/static/images/bkashLogo.png")} alt="login" />
                        <Typography mt={1} variant='poster' fontFamily={'Comic Sans MS, Comic Sans, cursive'}>Card Management System</Typography>
                    </SectionStyle>
                )}

                <Container>
                    <ContentStyle>
                        <Typography variant="h4" gutterBottom>
                            Register & Apply.
                        </Typography>

                        <Typography sx={{ color: 'text.secondary', mb: 5 }}>Create an account and apply for a card</Typography>

                        {/* <AuthSocial /> */}

                        <Box>
                            <Stack spacing={3}>
                                <Stack direction={{ xs: 'column', sm: 'row', md: 'column' }} spacing={2}>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <Tooltip title="Add Photo" arrow TransitionComponent={Zoom} placement="right">
                                                <SmallAvatar onClick={handlePhotoChangeClick} alt="Remy Sharp" src="/static/images/avatar/1.jpg">
                                                    <Iconify sx={{ borderRadius: 20, }} icon={"line-md:plus"} />
                                                </SmallAvatar>
                                            </Tooltip>
                                        }
                                        sx={{ mb: 4, mt: 2, alignSelf: "center" }}
                                    >
                                        <Avatar
                                            alt={"profile_photo"}
                                            src={image ? URL.createObjectURL(image) : String(process.env.PUBLIC_URL).concat("/static/images/unisex-avatar.webp")}
                                            sx={(theme) => ({
                                                width: 156, height: 156, border: "solid"
                                            })}
                                        />
                                    </Badge>
                                    <input type={"file"} style={{ display: "none" }} ref={hiddenFileInput} onChange={handlePhotoChange} accept="image/png, image/jpeg" />
                                    <Stack direction={'row'} spacing={2}>
                                        <TextField
                                            fullWidth
                                            required
                                            variant="standard"
                                            size='small'
                                            id="first_name"
                                            name="first_name"
                                            value={regForm.values.first_name}
                                            onChange={regForm.handleChange}
                                            error={!!regForm.errors.first_name}
                                            helperText={regForm.errors?.first_name}
                                            placeholder="type your first name"
                                            label="First Name"
                                        />
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            size='small'
                                            id="last_name"
                                            name="last_name"
                                            value={regForm.values.last_name}
                                            onChange={regForm.handleChange}
                                            error={!!regForm.errors.last_name}
                                            helperText={regForm.errors?.last_name}
                                            placeholder="type your last name"
                                            label="Last Name"
                                        />
                                    </Stack>
                                </Stack>
                                <FormControl>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        size='small'
                                        id="email"
                                        name="email"
                                        value={regForm.values.email}
                                        onChange={regForm.handleChange}
                                        error={!!regForm.errors.email}
                                        helperText={regForm.errors?.email}
                                        placeholder="type your email"
                                        label="Email"
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        required
                                        size='small'
                                        id="phone"
                                        name="phone"
                                        value={regForm.values.phone}
                                        onChange={regForm.handleChange}
                                        error={regForm.errors.phone_number || regForm.errors?.phone}
                                        helperText={regForm.errors?.phone_number || regForm.errors?.phone}
                                        placeholder="type your phone number"
                                        label="Phone"
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        id="password"
                                        name="password"
                                        size='small'
                                        value={regForm.values.password}
                                        onChange={regForm.handleChange}
                                        error={!!regForm.errors.password}
                                        helperText={regForm.errors?.password}
                                        label="Password"
                                        placeholder='Type your password'
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                {/* <FormControl>
                                    <CustomDatePicker error={!!regForm.errors.birth_date} label="Birth Date" sx={{ maxWidth: "25ch" }} onChange={(val) => { regForm.setFieldValue('birth_date', val) }} />
                                    <FormHelperText>{regForm.errors.birth_date}</FormHelperText>
                                </FormControl> */}
                                {/* <FormControl>
                                    <TextField
                                        fullWidth
                                        required
                                        size='small'
                                        id="address_line_1"
                                        name="address_line_1"
                                        value={regForm.values.address_line_1}
                                        onChange={regForm.handleChange}
                                        error={!!regForm.errors.address_line_1}
                                        helperText={regForm.errors?.address_line_1}
                                        // helperText="We will use this address to mail you percels, please help us with valid information."
                                        placeholder="type your address"
                                        label="Address"
                                        minRows={2}
                                        multiline
                                    />
                                    <FormHelperText>We will use this address to mail you percels, please help us with valid information.</FormHelperText>
                                    </FormControl> */}
                                                            {/* <Stack>
                                    <ImageUploader
                                        {...props}
                                        ref={pictureElement}
                                        withIcon
                                        withPreview
                                        onChange={onDrop}
                                        imgExtension={[
                                        ".jpg",
                                        ".jpeg",
                                        ]}
                                        maxFileSize={2000000}
                                        singleImage
                                    />
                                    </Stack> */}
                                    {/* <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Recieve NFC Card</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="delivery_method"
                                            id='delivery_method'
                                            value={regForm.values.delivery_method}
                                            onChange={regForm.handleChange}
                                        >
                                            <FormControlLabel value="Home" control={<Radio />} label="At home" />
                                            <FormControlLabel value="On-site" control={<Radio />} label="On-site" />
                                        </RadioGroup>
                                    </FormControl> */}
                                <LoadingButton fullWidth size="small" type="button" onClick={regForm.handleSubmit} variant="contained" loading={regForm.isSubmitting}>
                                    Register
                                </LoadingButton>
                            </Stack>

                        </Box>

                        <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                            By registering, I agree to CMS&nbsp;
                            <Link underline="always" color="text.primary" href="#">
                                Terms of Service
                            </Link>
                            {''} and {''}
                            <Link underline="always" color="text.primary" href="#">
                                Privacy Policy
                            </Link>
                            .
                        </Typography>

                        {!smUp && (
                            <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                                Already have an account?{' '}
                                <Link variant="subtitle2" to="/login" component={RouterLink}>
                                    Login
                                </Link>
                            </Typography>
                        )}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
