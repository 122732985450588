export const API_BASE_URLS={
    'dev':'https://bkashcard.clouddma.com/cms/',
    'prod':'https://bkashcard.clouddma.com/cms/',
    'dev_local':'http://localhost:8000/bkashcard/cms/'
}

export const MEDIA_BASE_URLS={
    'dev':'https://bkashcard.clouddma.com/cms',
    'prod':'https://bkashcard.clouddma.com/cms',
    'dev_local':'http://localhost:8000/bkashcard/cms'
}