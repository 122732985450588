import {
    Stack,
    Button,
    Container,
    Typography,
    Box, InputAdornment, IconButton, ButtonGroup, Tooltip, TextField,Slide,
    DialogActions,
    DialogContentText,
    FormControl,
    DialogContent,
    Dialog,
    DialogTitle
} from '@mui/material';
import { useFormik } from 'formik';
import { filter } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Iconify from '../../components/Iconify';
import LoaderDialog from '../../components/LoaderDialog';
import Page from '../../components/Page';
import { API } from '../../Config';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

// mock
// import transactions from '../_mock/user';


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function TransactionList() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState()
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [transactions, setTransactions] = useState([]);
    const [trxToRefund, setTrxToRefund] = useState(null);
    // op codes
    const [showLoaderDialog, setShowLoaderDialog] = useState(false);
    const [showRefundConfirmDialog, setShowRefundConfirmDialog] = useState(false);
    const [userPass,setUserPass] = React.useState('');


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = transactions.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByCardNumber = (event) => {
        setFilterName(event.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            setTransactions(transactions.filter((item, idx) => String(item.card_number).includes(searchText)))
        }
    }

    const filteredUsers = applySortFilter(transactions, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    const fetchTrxList = () => {
        API.get('paymentservice/admin/transactions/list/').then(res => {
            console.log(res.data.data)
            setTransactions(Array.from(res.data.data).map((item, idx) => {
                return {
                    id: idx + 1,
                    trx_id: item.transaction_id,
                    customer: item.customer,
                    purpose: item.purpose,
                    amount: item.amount,
                    status: item.is_refunded ? 'Refunded' : 'Paid',
                    datetime: item.created_at,
                    is_refunded: item.is_refunded
                }
            }))
        }).catch(err => {
            enqueueSnackbar(err.response.data.message || err.message, {
                variant: "error",
            });
        })
    }
    React.useEffect(() => {
        fetchTrxList();
    }, [])
    React.useEffect(() => {
        console.log('refund --',trxToRefund,showRefundConfirmDialog);
        if(trxToRefund){
            setShowRefundConfirmDialog(true);
        }
    }, [trxToRefund])
    const columns = [
        // { field: 'id', headerName: '#', width: 10 },
        {
            field: 'trx_id', headerName: 'Transaction ID', width: 230,
            renderCell: (params) => {
                return <Typography variant='span' sx={{ fontWeight: 'bold' }}>{params.row.trx_id}</Typography>
            }
        },
        {
            field: 'customer',
            headerName: 'Customer',
            description: 'The person who made this payment',
            sortable: false,
            width: 200,
            valueGetter: (params) => `${params.row?.customer || ''}`,
        },
        { field: 'purpose', headerName: 'Purpose', width: 220 },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 80,
            renderCell: (params) => {
                return <Typography variant='span' sx={{ fontWeight: 'bold' }}>{params.row.amount}</Typography>
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
        },
        {
            field: 'datetime',
            headerName: 'Date & Time',
            type: 'dateTime',
            valueGetter: (params) => { return new Date(params)
                 },
            width: 180,
        },
        {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            align: 'center',
            sortable: false,
            width: 180,
            renderCell: (params) => {
                const { is_refunded: isRefunded, trx_id: trxId } = params.row;
                const refund = (e) => {
                    setTrxToRefund(trxId);
                };

                return <ButtonGroup size="small" variant="text" aria-label="text button group">
                    {/* <Tooltip placement='top' title="View detail" arrow><IconButton disabled variant='contained' onClick={onClick}><VisibilityIcon/></IconButton></Tooltip> */}
                    <Tooltip placement='top' title={isRefunded ? "Refunded" : "Refund"} arrow>
                        <IconButton sx={theme => ({
                            '&:hover': {
                                background: theme.palette.secondary.main
                            }
                        })} type='button' disabled={isRefunded} variant='contained' onClick={refund}>
                            <Iconify icon={'mdi:cash-refund'} />
                        </IconButton>
                    </Tooltip>
                    {/* <ThreeDotMenu variant='contained'/> */}
                </ButtonGroup>;
            }
        },
    ];
    const refundApiCall = async (values, { setSubmitting, setErrors, resetForm }) => {
        API.get(`paymentservice/admin/transactions/${trxToRefund}/refund/${values.userPass}/`).then(res => {
            enqueueSnackbar('Transaction successfully refunded', {
                variant: "success",
            });
            setShowRefundConfirmDialog(false);
            fetchTrxList();
        }).catch(err => {
            enqueueSnackbar(err.response.data.message || err.message, {
                variant: "error",
            });
            setErrors({'userPass':err.response.data.message || err.message})
        })
    }
    const refundForm = useFormik({
        initialValues:{
            "userPass":'',
            "trxToRefund":''
        },
        validationSchema: Yup.object().shape({
            userPass: Yup.string().required('This field is must')
        }),
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: refundApiCall
    })

    const onEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            refundForm.handleSubmit()
        }
    }

    return (
        <>
            <Page title="Transactions">
                <LoaderDialog open={showLoaderDialog} url={null} />
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Transactions
                        </Typography>
                        {/* <Button variant="contained" component={RouterLink} to="/dashboard/transactions/add" startIcon={<Iconify icon="eva:plus-fill" />}>
                            New Customer
                        </Button> */}
                    </Stack>

                    <Box>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <TextField
                                size='small'
                                autoFocus
                                fullWidth
                                disableUnderline
                                placeholder="Search…"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyPress={handleKeyPress}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                        </InputAdornment>,
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Button size='small' variant="outlined" onClick={() => { }}>
                                                Search
                                            </Button>
                                        </InputAdornment>
                                }}
                                sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                            />

                        </Stack>
                        <DataGrid
                            autoHeight {...transactions}
                            disableSelectionOnClick
                            rows={transactions}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            density="comfortable"
                            // checkboxSelection
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            onRowClick={(rowData) => { console.log(rowData.row) }}
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                                    outline: "none !important",
                                },
                            }}
                        />
                    </Box>
                </Container>
                <div>
                    <Dialog open={showRefundConfirmDialog} onClose={refundForm.handleReset} TransitionComponent={Transition}>
                        <DialogTitle>{"Refund confirmation"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {"Are you sure that you want to refund this transaction?"}
                            </DialogContentText>
                            <FormControl fullWidth sx={{marginTop:2}}>
                                <TextField
                                    fullWidth
                                    required
                                    variant='standard'
                                    id="userPass"
                                    name="userPass"
                                    type="password"
                                    value={refundForm.values.userPass}
                                    onChange={refundForm.handleChange}
                                    error={refundForm.errors.userPass && refundForm.touched.userPass}
                                    helperText={refundForm.errors.userPass? refundForm.errors.userPass:'Enter your 8 digits password'}
                                    placeholder="type your 8 digits password.."
                                    label="Password"
                                    onKeyDown={onEnterKeyPress}
                                />
                            </FormControl>
                        </DialogContent>

                        <DialogActions>
                            <Button color='error' disabled={refundForm.isSubmitting} onClick={(e)=>{refundForm.handleReset();setShowRefundConfirmDialog(false); setTrxToRefund(null)}}>Cancel</Button>
                            <LoadingButton type="button" disabled={refundForm.isSubmitting} loading={refundForm.isSubmitting} onClick={refundForm.handleSubmit}>Confirm</LoadingButton>
                        </DialogActions>
                    </Dialog>
                </div>
            </Page>
        </>
    );
}
