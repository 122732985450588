import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, Slide } from '@mui/material';
import Iconify from '../../../components/Iconify';


const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog(props) {
    const { open, onClose, onConfirm, title, contentText,data } = props
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const handleClose = () => {
        setIsSubmitting(false)
        onClose(false);
    };
    const handleConfirm = () => {
        setIsSubmitting(true)
        onConfirm(data) // passing data back to further actions on confirmation
    };

    React.useEffect(() => {
        setIsSubmitting(false)
    }, [open])

    return (
        <div>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {contentText}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <LoadingButton type="button" loading={isSubmitting} onClick={handleConfirm}>Confirm</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}