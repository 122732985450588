import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../Config'

const initialState = {
    data: [],
    status: 'idle',
    error: ''
}

export const fetchTransactionsThunk = createAsyncThunk('trx/list/', async () => {
    const response = await API.get('paymentservice/user/transactions/list/')
    console.log('trx', response)
    return response.data
})

export const trxSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        changeState: (state, val) => {
            console.log('dispatching ----- ', val)
            state.data = val.payload
        },
    },
    extraReducers: {
        [fetchTransactionsThunk.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchTransactionsThunk.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.data = action.payload.data
        },
        [fetchTransactionsThunk.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
    }
})

// Action creators are generated for each case reducer function
export const { changeState } = trxSlice.actions
export default trxSlice.reducer
