import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Stack, Typography, Card, Box, Tooltip, Zoom } from "@mui/material";
import { styled } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import { useDispatch, useSelector } from "react-redux";
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Iconify from "../../components/Iconify";
import Page from "../../components/Page";
import FormDialog from "./ProfileUpdateDialog"
import HardAlert from "../../components/HardAlert";
import { fetchGeoDataThunk } from "../../store/slices/GeoDataSlice";


const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 42,
    height: 42,
    border: `2px solid ${theme.palette.background.paper}`,
    background: theme.palette.primary.darker,
    cursor: "pointer"
}));

export default function CustomerProfile({ color = 'primary', sx, ...other }) {
    const [showPhotoDialog, setShowPhotoDialog] = useState(false)
    const myDetail = useSelector(state => state.myDetails.data)
    const [showUpdateDialog, setShowUpdateDialog] = useState(false)
    const location = useLocation()
    const dispatch = useDispatch()
    const handlePhotoChangeClick = event => {
        setShowPhotoDialog(true)
    };

    useEffect(() => {
        if (location.state?.showUpdateDialog) {
            setShowUpdateDialog(true)
        }
        dispatch(fetchGeoDataThunk())
    }, [])

    return (
        <>
            <Page title="My Profile">
                <HardAlert open={showPhotoDialog} onUpdate={(param) => { setShowPhotoDialog(false) }} onCancel={(param) => { setShowPhotoDialog(false) }} header="Update Profile Photo" body="Click choose photo and then upload to set new profile picture"/>
                <Container maxWidth="lg">
                    <Stack display="flex" flexDirection={'row'} alignItems="center" justifyContent="flex-start" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            My Profile
                        </Typography>
                    </Stack>
                    <Grid container>
                        <Grid item md={6} sm={6} xs={12} mdOffset={3} smOffset={3}>
                            <Card sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: (theme) => theme.palette[color].main,
                                // color:(theme) => theme.palette[color].lighter,
                                background: 'none',
                                bgcolor: (theme) => theme.palette.secondary.lighter,
                                // background: 'linear-gradient(90deg, rgba(2,0,36,1) 17%, rgba(0,253,222,1) 89%)',
                                // backgroundImage: `url(${'/static/images/banner.webp'})`,
                                ...sx,
                            }}
                                {...other}
                            >
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <Tooltip title="Change Photo" arrow TransitionComponent={Zoom} placement="right">
                                            <SmallAvatar sx={{backgroundColor: theme=> theme.palette.primary.main}} onClick={handlePhotoChangeClick} alt="Remy Sharp" src="/static/images/avatar/1.jpg">
                                                <Iconify sx={{ borderRadius: 20,  }} icon={"line-md:pencil"} />
                                            </SmallAvatar>
                                        </Tooltip>
                                    }
                                    sx={{ mb: 4, mt: 2, }}
                                >
                                    <Avatar
                                        alt={myDetail.first_name}
                                        // src={myDetail?.profile_pic}
                                        src={myDetail.profile_pic? myDetail.profile_pic : String(process.env.PUBLIC_URL).concat("/static/images/unisex-avatar.webp")}
                                        sx={(theme) => ({
                                            width: 156, height: 156
                                        })}
                                    />
                                    
                                </Badge>
                                <Box mb={3} sx={(theme) => ({
                                    [theme.breakpoints.down('md')]: {
                                        alignSelf: "flex-start",
                                        px: 6
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        alignSelf: "flex-start",
                                        px: 6
                                    },
                                })}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Profile Information <Iconify onClick={() => setShowUpdateDialog(true)} sx={{ width: 25, height: 25, pt: 1, cursor: "pointer" }} icon={"line-md:pencil"} />
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                        Name
                                    </Typography>
                                    <Typography variant="subtitle1" component="div" gutterBottom>
                                        {String(myDetail.first_name).concat(" ").concat(myDetail.last_name)}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                        Email
                                    </Typography>
                                    <Typography variant="subtitle1" component="div" gutterBottom>
                                        {myDetail.email}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                        Phone
                                    </Typography>
                                    <Typography variant="subtitle1" component="div" gutterBottom>
                                        {myDetail.phone}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                        Address
                                    </Typography>
                                    <Typography variant="subtitle1" component="div" gutterBottom>
                                        {myDetail?.user_address !== null && String(myDetail.user_address?.address_line_1).concat(', ').concat(myDetail.user_address?.city).concat(', ').concat(myDetail.user_address?.state)}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                        {/* <Grid >

                        </Grid> */}
                    </Grid>

                </Container>
                <FormDialog open={showUpdateDialog} onClose={setShowUpdateDialog} />
            </Page>
        </>
    )
}