// component
import { children } from '../../routes';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const defaultRoute = [
    
    // {
    //     title: 'dashboard',
    //     path: '/dashboard/app',
    //     icon: getIcon('eva:pie-chart-2-fill'),
    // },
]

export const customerNavConfig = [
    ...defaultRoute,
    {
        title: 'How To',
        path: '/dashboard/how-to',
        icon: getIcon('ep:guide'),
    },
    {
        title: 'My NFC Card',
        path: '/dashboard/my-card',
        icon: getIcon('ic:outline-credit-card'),
    },
    {
        title: 'My Supplementary Cards',
        path: '/dashboard/my-family-cards',
        icon: getIcon('ic:outline-credit-card'),
    },
    {
        title: 'profile',
        path: '/dashboard/profile',
        icon: getIcon('mdi:account'),
    },
];

export const operatorNavConfig = [
    ...defaultRoute,
    {
        title: 'Customers',
        path: '/dashboard/customers/list',
        icon: getIcon('mdi:people'),
    },
    {
        title: 'profile',
        path: '/dashboard/profile',
        icon: getIcon('mdi:account'),
    },
];

export const orgAdminNavConfig = [
    ...defaultRoute,
    // {
    //     title: 'Customers',
    //     path: '/dashboard/customers/list',
    //     icon: getIcon('mdi:people'),
    // },
    {
        title: 'Transactions',
        path: '/dashboard/transactions/list',
        icon: getIcon('mdi:money'),
    },

    {
        title: 'POS Management',
        path: '/dashboard/pos/management',
        icon: getIcon('mdi:point-of-sale'),
    },
    {
        title: 'profile',
        path: '/dashboard/profile',
        icon: getIcon('mdi:account'),
    },

];

