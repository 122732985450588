import * as React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Avatar,Box, Tooltip,Badge,Zoom } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
import { FILE_API} from '../Config';
import { fetchUserDetail } from '../store/slices/AuthSlice';
import Iconify from './Iconify';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 42,
    height: 42,
    border: `2px solid ${theme.palette.background.paper}`,
    background: theme.palette.primary.darker,
    cursor: "pointer"
}));


const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function HardAlert(props) {
    const dispatch = useDispatch()
    const { icon, header, body, open, onUpdate, onCancel, onClose } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [image, setImage] = React.useState(null)

    const toClear = () => {
        // pictureElement.current.clearPictures();
        document.getElementById('fileChooser').value=null;
        setImage(null);
    }

    const onDropImage = picture => {
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const fileExtension = picture.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            enqueueSnackbar('Unsupported file extension. Allowed extensions are: jpg, jpeg, png', {
                variant: "warning",
            });
            setImage(null);
        } else {
            if (picture.size / 1024 / 1024 > 5) {
                enqueueSnackbar("Maximum file size (5MB) exceeded!", {
                    variant: "warning",
                });
                return;
            }
            setImage(picture);
        }
    };

    const handleClose = () => {
        toClear()
        onCancel(false)
    };

    const updatePhoto = async () => {
        const formData = new FormData()
        formData.append("photo", image)

        FILE_API.post("auth/user/photo/update/", formData).then(res => {
            toClear()
            setImage(null)
            dispatch(fetchUserDetail())
            onUpdate()
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
        }).catch(err => {
            console.log(err)
            enqueueSnackbar(err.response?.data?.message??err.message, {
                variant: "warning",
            });

        })
    }
    return (
        <div>

            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>{header}</DialogTitle>
                <form encType='multipart/form-data'>
                    <DialogContent>
                        <DialogContentText>
                            {body}
                        </DialogContentText>
                        <input style={{display:'none'}} id="fileChooser" onChange={(e) => onDropImage(e.target.files[0])} type='file' accept=".jpg,.jpeg,.png" />
                        {image && 
                            <Box sx={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center'
                            }}>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <Tooltip title="Change Photo" arrow TransitionComponent={Zoom} placement="right">
                                            <SmallAvatar onClick={()=>document.getElementById('fileChooser').click()} sx={{backgroundColor: theme=> theme.palette.primary.main}} alt="Remy Sharp" src="/static/images/avatar/1.jpg">
                                                <Iconify sx={{ borderRadius: 20,  }} icon={"line-md:remove"} />
                                            </SmallAvatar>
                                        </Tooltip>
                                    }
                                    sx={{ mb: 4, mt: 2, }}
                                >
                                    <Avatar
                                        alt={'hh'}
                                        // src={myDetail?.profile_pic}
                                        src={URL.createObjectURL(image)}
                                        sx={(theme) => ({
                                            width: 156, height: 156
                                        })}
                                    />
                                    
                                </Badge>
                            </Box>
                        }
                    </DialogContent>

                    <DialogActions>
                        <Button type='button' variant='contained' color='error' onClick={handleClose}>Cancel</Button>
                        {image && <LoadingButton disabled={false} variant='contained' type="button" loading={false} onClick={updatePhoto}>Update</LoadingButton>}
                        {!image && <LoadingButton disabled={false} variant='contained' type="button" loading={false} onClick={() => document.getElementById('fileChooser').click()}>Choose Photo</LoadingButton>}
                    </DialogActions>
                </form>
            </Dialog>

        </div>
    );
}